import getLastTld from 'common/services/utils/getLastTld';

export const getContactUrl = () => {
  const country = getLastTld();
  switch (country) {
    case 'ar':
      return 'https://bigbox.kustomer.help/es_ar/contact/formulario-ar-H1sZ3sXE6';
    case 'es':
      return 'https://bigbox.kustomer.help/es_ar/contact/formulario-ar-H1sZ3sXE6';
    case 'uy':
      return 'https://bigbox-uy.kustomer.help/es_ar/contact/formulario-uy-H1zAtE94T';
    case 'pe':
      return 'https://bigbox-pe.kustomer.help/es_ar/contact/contactanos-pe-rJzyKE9Ep';
    case 'mx':
      return 'https://bigbox-mx.kustomer.help/es_ar/contact/formulario-mx-rklcp4H4p';
    case 'cl':
      return 'https://bigbox-cl.kustomer.help/es_ar/contact/bigbox-cl-Bkm_tEBVp';
  }
};
