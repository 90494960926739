import dayjs from 'dayjs';
import 'dayjs/locale/es';

dayjs.locale('es');

/**
 * Take toFormat input and transform to selected formatString using dayjs
 * @param toFormat {string} date string from calendar input
 * @param formatString {string} desire format
 * @return {string} Formatted date string
 */
function date(toFormat, formatString = 'DD/MM/YYYY') {
  return dayjs(toFormat).format(formatString);
}

export default date;
