import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import useAccount from 'composables/useAccount';
import NavbarMobileUser from 'components/NavbarMobileUser/NavbarMobileUser.vue';
import NavbarMobileUserOut from 'components/NavbarMobileUserOut/NavbarMobileUserOut.vue';
import close from 'common/icons/Close.svg';
import chevron from 'common/icons/Chevron.svg';
import logo from 'assets/icons/bigbox.svg';
import store from 'assets/icons/store.svg';
import corporate from 'assets/icons/corporate.svg';
import { getCorpoUrl } from 'common/utils/getCorpoUrl';

@Component({
  components: {
    NavbarMobileUser,
    NavbarMobileUserOut,
  },
  props: {
    user: {
      type: Object,
      description: 'Null if not logged',
    },
  },
  setup() {
    const { onLogout } = useAccount();

    return {
      onLogout,
    };
  },
})
export default class NavbarMobileComponent extends Vue {
  icons = {
    close,
    chevron,
    logo,
    store,
    corporate,
  };

  async logout() {
    const { data } = await this.onLogout();
    const { logout } = data;
    if (!logout) return;
    this.$emit('refetchUser');
    this.$router.push({ name: 'login' });
  }

  get corpoUrl() {
    return getCorpoUrl();
  }

  @Watch('$route')
  routeUpdated() {
    this.$emit('toggleMobileMenu');
  }
}
