<template>
  <div class="notification" :style="`background-color: ${color}`" />
</template>

<script>
  import Vue from 'vue';
  import Component from 'vue-class-component';

  @Component({
    props: {
      color: {
        type: String,
        default: '#ff6c5f',
      },
    },
  })
  export default class NavbarUserMenuComponent extends Vue {}
</script>

<style lang="scss" scoped>
  .notification {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid white;
    box-sizing: border-box;
  }
</style>
