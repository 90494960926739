/**
 * Description: Use imgProxy to optimize images and resize.
 * Temporary only for square images.
 * @param {String} imageUrl Product_image_url
 * @param {String} size Desired size
 * @return {String} Image optimized and resized
 */
const optimizeImage = (imageUrl, size) => {
  let base64Url = btoa(imageUrl);
  return `https://media.bigbox.com.ar/1/fit/${size}/0/ce/1/${base64Url}`;
};

export default optimizeImage;
