export const INITIAL_STATE_BOX = {
  box_category_name: [],
  box_has_online_reservation: false,
  box_participants: [],
  box_price: {
    min_price: 0,
    max_price: 0,
  },
  box_province: [],
  box_tag: [],
};
export const INITIAL_STATE_ACTIVITIES = {
  destination: [],
  category: [],
  has_online_reservation: false,
  months: [],
  participants: [],
  province: [],
  price: {
    min_price: 0,
    max_price: 0,
  },
  relevance: [],
  subcategory: [],
  tag: [],
};
export const INITIAL_STATE_TRAVEL = {
  months: [],
  comboactivity_provinces: [],
  price: {
    min_price: 0,
    max_price: 0,
  },
};
