import VueApollo from 'vue-apollo';

import createApolloClient from 'common/services/apolloClient';

import FeaturesQuery from 'assets/graphql/queries/Features.graphql';

import Store from './Store';


function initStore(store, { requestConfig, apolloState, viewportWidth } = {}) {
  const isServer = process.env.VUE_ENV === 'server';
  const host = isServer ? requestConfig.headers.HOST : window.location.host;
  store.host = host;
  store.viewportWidth = isServer ? (viewportWidth || 1366) : window.innerWidth;

  store.apolloClient = initializeApolloClient({ requestConfig, apolloState });
  const apolloProvider = new VueApollo({
    defaultClient: store.apolloClient,
  });
  Object.defineProperty(store, '$apolloProvider', {
    value: apolloProvider,
    enumerable: true,
    configurable: true,
    writable: true,
  });

  addSmartQueries(store);
}


function initializeApolloClient({ requestConfig, apolloState }) {
  const linkOptions = {};
  if (requestConfig) {
    if (requestConfig.headers) {
      linkOptions.headers = requestConfig.headers;
    }
    if (requestConfig.baseURL) {
      linkOptions.uri = `${requestConfig.baseURL}graphql/`;
    }
  }
  return createApolloClient({
    linkOptions,
    state: apolloState,
  });
}


function addSmartQueries(store) {
  store.$apollo.addSmartQuery('features', {
    query: FeaturesQuery,
    // Avoid making additional request:
    fetchPolicy: 'cache-only',
    errorPolicy: 'ignore',
  });
}


export default function createStore(options) {
  const store = new Store();
  initStore(store, options);
  return store;
}
