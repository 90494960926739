import Vue from 'vue';
import Component from 'vue-class-component';
import SearchBar from 'components/SearchBar/SearchBar.vue';
import CartBlock from 'components/CartBlock/CartBlock.vue';
import NavbarUser from 'components/NavbarUser/NavbarUser.vue';
import NavbarMobile from 'components/NavbarMobile/NavbarMobile.vue';
import PopoverRegalendario from 'components/PopoverRegalendario/PopoverRegalendario.vue';
import { getCorpoUrl } from 'common/utils/getCorpoUrl';

@Component({
  components: {
    NavbarUser,
    NavbarMobile,
    SearchBar,
    CartBlock,
    PopoverRegalendario,
  },
  props: {
    user: {
      description: 'Object or Null type',
      required: true,
    },
    showMobileMenu: {
      type: Boolean,
      required: true,
    },
    cart: {
      type: Object,
      default: () => {},
    },
    cartIsLoading: {
      type: Boolean,
      required: true,
    },
  },
})
export default class NavbarComponent extends Vue {
  isHomeView = false;
  regalendario = {
    step: 0,
    adviceFlag: false,
  };

  mounted() {
    this.isHomeView = this.$route.name === 'home';
    this.$root.$on('RegalendarioPopover', step => {
      this.regalendario.step = step;
      this.regalendario.adviceFlag = true;
    });
  }

  updated() {
    this.isHomeView = this.$route.name === 'home';
  }

  handleRegalendarioClick() {
    if (!this.user) {
      return this.$router.push({
        name: 'login',
        query: { next: this.$route.fullPath },
      });
    }

    const {
      isRegalendarioView,
      isHomeView,
      hasReminderDiscount,
      haveReminders,
    } = {
      isRegalendarioView: this.$route.name === 'regalendario',
      isHomeView: this.$route.name === 'home',
      hasReminderDiscount: this.user.has_created_three_reminders,
      haveReminders: Boolean(this.user.reminders.length),
    };
    if (isRegalendarioView) {
      // to avoid duplicated navigation
      return;
    } else if (!isHomeView) {
      return this.redirectToRegalendario();
    } else if (!haveReminders) {
      // if has 0 reminders
      return this.$emit('toggleOnboarding', true);
    } else if (!hasReminderDiscount) {
      // if has 1 or 2 reminders
      return this.$emit('toggleRemindersModalView', true);
    } else {
      // if has more than or equal to 3
      this.redirectToRegalendario();
    }
  }

  redirectToRegalendario() {
    return this.$router.push({ name: 'regalendario' });
  }

  get corpoUrl() {
    return getCorpoUrl();
  }

  focusSearchBar() {
    this.$refs.searchBar.$refs.searchInput.focus();
  }

  refetchUser() {
    this.$emit('refetchUser');
  }
}
