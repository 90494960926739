import app from 'main.js';
import latamLocale from 'locale/latam';
import spainLocale from 'locale/spain';

export default function keywordTranslate(keyword) {
  let transLatamKeyword = latamLocale['latam'][keyword];
  let transSpainKeyword = spainLocale['es'][keyword];
  if (app.$store.isLatam) return transLatamKeyword || keyword;
  if (app.$store.tld === 'es') return transSpainKeyword || keyword;
  return keyword;
}
