import Vue from 'vue';
import Component from 'vue-class-component';

import optimizeImage from 'common/utils/optimizeImage.js';

import searchIcon from 'assets/icons/search_icon_new.svg';
@Component({
  props: {
    isPredictive: {
      type: Boolean,
      default: false,
      description: 'Boolean if show empty query or predictive',
    },
    activitiesSearchResults: {
      required: false,
      description: 'Activities objects from search',
    },
    boxSearchResults: {
      required: true,
      description: 'Boxes objects to display',
    },
    suggestionsResults: {
      required: true,
      description: 'Suggestions to display',
    },
    searchValue: {
      required: false,
      description: 'Actual predictive search text',
    },
    loading: {
      required: true,
      type: Boolean,
    },
    isSpain: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
    optimizeImage,
  },
})
export default class SearchOptionsComponent extends Vue {
  searchIcon = searchIcon;
  searchText = this.isSpain
    ? 'Busca resultados de '
    : ' Ver todos los resultados de ';
}
