import apolloClient from 'utils/apolloClient';
import LandingCategories from 'assets/graphql/queries/LandingCategories.graphql';

const landingsService = {
  getLandingsCategories: async () => {
    try {
      const { data } = await apolloClient.query({
        query: LandingCategories,
      });

      return data.landing_categories;
    } catch (err) {
      throw new Error(err);
    }
  },
};

export default landingsService;
