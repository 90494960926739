export default {
  es: {
    'a tu regalador': 'a la persona que te lo regaló',
    'con vos': 'contigo',
    'Regalos con opciones': 'Todas las colecciones',
    a: 'por',
    Abonás: 'Abonas',
    abrí: 'abre',
    Abrí: 'Abre',
    aca: 'aquí',
    acá: 'aquí',
    activá: 'activa',
    Activá: 'Activa',
    agregá: 'agrega',
    Agregá: 'Agrega',
    agregala: 'agrégala',
    añadilo: 'añádelo',
    Aumentá: 'Aumenta',
    Ayudá: 'Ayuda',
    Buscá: 'Busca',
    buscás: 'buscas',
    Calificá: 'Califica',
    Cargá: 'Carga',
    Chequeá: 'Chequea',
    compartí: 'comparte',
    Compartí: 'Comparte',
    completá: 'completa',
    Completá: 'Completa',
    Comprobá: 'Comproba',
    Conocé: 'Conoce',
    Contactá: 'Contacta',
    contactanos: 'Contáctanos',
    Contactanos: 'Contáctanos',
    Contactate: 'Contáctate',
    Contanos: 'Cuéntanos',
    Convertila: 'Conviértela',
    convertite: 'conviértete',
    Coordiná: 'Coordina',
    creá: 'crea',
    debés: 'debes',
    decile: 'dile',
    dejá: 'deja',
    Dejanos: 'Déjanos',
    Descargalo: 'Descárgalo',
    Descubrí: 'Descubre',
    disfrutá: 'disfruta',
    Disfrutá: 'Disfruta',
    Editá: 'Edita',
    elegí: 'elige',
    Elegí: 'Elige',
    encontrás: 'encuentras',
    envianos: 'envíanos',
    Envianos: 'Envíanos',
    enviás: 'envías',
    Escribinos: 'Escríbenos',
    esperás: 'esperas',
    Extendé: 'Extiende',
    Fidelizá: 'Fideliza',
    ganá: 'gana',
    Ganá: 'Gana',
    Generá: 'Genera',
    hacé: 'haz',
    Hacé: 'Haz',
    ingresá: 'ingresa',
    Ingresá: 'Ingresa',
    Iniciá: 'Inicia',
    Instalá: 'Instala',
    intentá: 'intenta',
    Intentalo: 'Inténtalo',
    marcá: 'marca',
    Mirá: 'Mira',
    Modificá: 'Modifica',
    necesitás: 'necesitas',
    Necesitás: 'Necesitas',
    Obsequiá: 'Obsequia',
    Obtené: 'Obtén',
    podés: 'puedes',
    Podés: 'Puedes',
    Posicioná: 'Posiciona',
    Potenciá: 'Potencia',
    Preguntale: 'Pregúntale',
    Preguntales: 'Pregúntales',
    presentalo: 'preséntalo',
    Probá: 'Prueba',
    querés: 'quieres',
    Querés: 'Quieres',
    Realizá: 'Realiza',
    recibí: 'recibe',
    recordá: 'recuerda',
    Recordá: 'Recuerda',
    regalá: 'regala',
    Regalá: 'Regala',
    Regalate: 'Regálate',
    registrate: 'regístrate',
    Registrate: 'Regístrate',
    Reservá: 'Reserva',
    revisá: 'revisa',
    sabés: 'sabes',
    Sabés: 'Sabes',
    seguí: 'sigue',
    Seguí: 'Sigue',
    seguís: 'sigues',
    Seguís: 'Sigues',
    seleccioná: 'selecciona',
    Sorprendé: 'Sorprende',
    sorprendete: 'sorpréndete',
    Suscribite: 'Suscríbete',
    tenés: 'tienes',
    Tenés: 'Tienes',
    tocá: 'toca',
    trabajá: 'trabaja',
    Viví: 'Vive',
    vos: 'tu',
  },
};
