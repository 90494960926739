import latamLocale from 'locale/latam';
import spainLocale from 'locale/spain';

export default function translate(keyword, store) {
  let transLatamKeyword = latamLocale['latam'][keyword];
  let transSpainKeyword = spainLocale['es'][keyword];
  if (store.isLatam) return transLatamKeyword || keyword;
  if (store.tld === 'es') return transSpainKeyword || keyword;
  return keyword;
}
