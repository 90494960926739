import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: {
    DiscountLabel: () => import('common/components/DiscountLabel'),
  },
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
    boxes: {
      type: Array,
      default: () => [],
    },
  },
})
export default class HeaderMenuSimpleComponent extends Vue {
  getBoxUrl(box) {
    const category = this.getBoxCategory(box);
    return {
      name: 'box',
      params: {
        categorySlug: category.slug,
        boxSlug: box.slug,
      },
    };
  }

  getBoxCategory(box) {
    return this.categories.find(c => c.id === box.category_id);
  }

  getCurrency(box) {
    const currency = this.$store.tld === 'pe' ? 'S/ ' : '$';
    let price = this.formatMoney(box.price, false, ',', '.');
    price = `${currency}${price}`;
    if (box.price_in_usd && box.use_price_in_usd) {
      price = `U$D ${box.price_in_usd}`;
    }
    return price;
  }

  getCurrencyOriginalPrice(box) {
    const currency = this.$store.tld === 'pe' ? 'S/ ' : '$';
    let price = this.formatMoney(
      box.original_price.toString(),
      false,
      ',',
      '.',
    );
    price = `${currency}${price}`;
    if (box.price_in_usd && box.use_price_in_usd) {
      price = `U$D ${box.price_in_usd}`;
    }
    return price;
  }

  formatMoney(amount, decimalCount, decimal = '.', thousands = ',') {
    decimalCount = Math.abs(decimalCount);
    decimalCount = Number.isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';
    const i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)),
      10,
    ).toString();
    const j = i.length > 3 ? i.length % 3 : 0;
    const decimals = decimalCount
      ? `${decimal}${Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(2)}`
      : '';

    let price = j ? i.substr(0, j) + thousands : '';
    price = `${price}${i
      .substr(j)
      .replace(/(\d{3})(?=\d)/g, `$1${thousands}`)}`;

    return `${negativeSign}${price}${decimals}`;
  }
}
