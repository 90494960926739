/**
 * Returns the plural of the word provided if the amount provided is not equals to 1
 * In case of irregular pluralizations, use an Array with the singular and plural words to use
 * @param {Number} amount - Required
 * @param {String | Array<String>} word - Requied
 * @param {Boolean} returnAmount - Optional: Use true in case you want to return the number amount with the text
 */
export default function pluralizate(amount, word, returnAmount = false) {
  let pluralWord;
  // Check if the word parameter is an array
  // The amount is parsed to number with the Unary Plus because eslint doesn't let me use double equals
  if (word instanceof Array) {
    pluralWord = (+amount === 1) ? word[0] : word[1];
  } else {
    pluralWord = (+amount === 1) ? word : `${word}s`;
  }
  return (returnAmount) ? `${amount} ${pluralWord}` : pluralWord;
}
