<script setup>
  import { computed } from 'vue';
  import currency from 'common/filters/currency';

  const props = defineProps({
    name: {
      type: String,
      default: '',
    },
    img: {
      type: String,
      default: '',
    },
    discountPercentage: {
      type: String,
      default: false,
    },
    price: {
      default: '',
    },
    priceWithoutDiscount: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
  });

  const isOnSale = computed(() => {
    return !!Number(props.discountPercentage);
  });
</script>

<template>
  <router-link :to="url" class="card">
    <div class="tag absolute figtree-font">Solo en Bigbox</div>
    <img class="image" :src="img" alt="product" />
    <div class="data">
      <p class="name figtree-font">{{ name }}</p>
      <div class="price">
        <span class="typography-title-small-1">{{
          currency(props.price)
        }}</span>
        <span
          v-if="isOnSale"
          class="price__without-discount typography-subtitle-small-4"
        >
          {{ currency(priceWithoutDiscount) }}
        </span>
      </div>
    </div>
  </router-link>
</template>

<style scoped lang="scss">
  .card {
    position: relative;
    display: flex;
    gap: 12px;
    flex-direction: column;
    align-items: flex-start;
    height: fit-content;
    width: 272px;

    @media (max-width: $screen-sm-max) {
      width: 163px;
      gap: 8px;
    }

    .tag {
      top: 12px;
      left: 16px;
      padding: 1px 4px;
      border-radius: 8px;
      background: $WHITE;
      color: $DARK-GRAY-2;
      font-size: 12px;
      font-weight: 600;
      @media screen and (max-width: $screen-sm) {
        font-size: 8px;
      }
    }
    .image {
      width: 100%;
      height: 185px;
      flex-shrink: 0;
      border-radius: 8px;

      @media (max-width: $screen-sm-max) {
        width: 163px;
        height: 115px;
      }
    }

    .data {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .name {
        color: $DARK-GRAY-2;
        font-size: 20px;
        line-height: 26px;
        font-weight: 600;

        @media screen and (max-width: $screen-sm) {
          font-size: 16px;
        }
      }

      .price {
        display: flex;
        align-items: center;

        &__without-discount {
          margin-left: 4px;
          color: #828282;
          text-decoration: line-through;
        }
      }
    }
  }
</style>
