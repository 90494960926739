import Vue from 'vue';
import Router from 'vue-router';
import VueApollo from 'vue-apollo';
import Meta from 'vue-meta';
import lazySizes from 'lazysizes';
import createRouter from 'route';
import createStore from 'store';
import gql from 'graphql-tag';
import capitalize from 'lodash/capitalize';

import App from 'App.vue';

import pluralizate from 'common/services/pluralizate';
import currencyFilter from 'common/filters/currency';
import getLastTld from "common/services/utils/getLastTld";
import createApolloClient from 'common/services/apolloClient';
import sentryInit from "common/plugins/sentry";
import 'common/scss/common-sass.scss';

import date from 'filters/date/date.filter';
import storeInitMixin from 'mixins/storeInit';
import commonMixin from 'mixins/common';
import translate from 'utils/translate';
import SiteConfig from 'assets/graphql/queries/SiteConfig.graphql';
import { createPinia, PiniaVuePlugin } from 'pinia'

Vue.use(Router);
Vue.use(VueApollo);
Vue.use(Meta);

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.mixin(commonMixin);
Vue.mixin(storeInitMixin);

Vue.filter('currency', currencyFilter);
Vue.filter('date', date);
Vue.filter('capitalize', capitalize);

/* To use caché */
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
    .catch((err) => {
      throw ("serviceWorker could not be registered", err);
    });
  });
}

Object.assign(Vue.prototype, {
  SCREEN_XS_MAX: 767,
  SCREEN_SM_MIN: 768,
  SCREEN_SM_MAX: 991,
  SCREEN_MD_MIN: 992,
  SCREEN_MD_MAX: 1999,
  SCREEN_LG_MIN: 1200,
  $screen: Vue.observable({
    width: window.innerWidth,
    height: window.innerHeight,
  }),
  $siteConfig: Vue.observable({
    config: {},
  }),
  $translate(keyword) {
    return translate(keyword, this.$store);
  },
  plural(amount, word, returnAmount) {
    return pluralizate(amount, word, returnAmount);
  },
  currency(val, decimal, showUSD) {
    return currencyFilter(val, decimal, showUSD);
  },
});

const store = createStore();

const apolloClient = createApolloClient();
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

const router = createRouter(apolloClient);

lazySizes.cfg.lazyClass = 'lazy-load';
lazySizes.cfg.hFac = 0.1;

window.addEventListener('resize', () => {
  Vue.prototype.$screen.width = window.innerWidth;
  Vue.prototype.$screen.height = window.innerHeight;
});

apolloClient.query({
  query: SiteConfig,
}).then((response) => {
  Vue.prototype.$siteConfig.config = response.data.siteConfig;
});

if (['co'].includes(getLastTld())) {
  apolloClient.query({
    query: gql`
      query AuthUser {
        auth_user {
          id
          is_staff
        }
      }
    `,
  }).then((response) => {
    if (
      process.env.NODE_ENV === 'production' &&
      (!response.data.auth_user || !response.data.auth_user.is_staff)
    ) { window.location = '/landing/'; }
  });
}

/* Sentry config */
const sentry_key = 'c5091407b61a8ec376f62ac4532c245a';
const sentry_id = '6';
sentryInit(sentry_key,sentry_id, Vue, router );

const app = new Vue({
  el: '#app',
  router,
  store,
  pinia,
  apolloProvider,
  render: h => h(App),
});

export default app;
