import Vue from 'vue';
import Component from 'vue-class-component';
import Navbar from 'components/Navbar/Navbar.vue';
import BaseFooter from 'components/BaseFooter';

import useAccount from 'composables/useAccount';

import CategoriesQuery from 'assets/graphql/queries/Categories.graphql';
import CartQuery from 'assets/graphql/queries/Cart.graphql';

@Component({
  components: {
    Navbar,
    BaseFooter,
  },
  apollo: {
    categories: {
      query: CategoriesQuery,
    },
    cart: {
      query: CartQuery,
    },
  },
  setup() {
    const { getUserData, getUserLogged, onUserStatus, onUserData, loading } =
      useAccount();

    return {
      auth_user: getUserData,
      logged_user: getUserLogged,
      onUserStatus,
      onUserData,
      loading,
    };
  },
})
export default class BaseViewComponent extends Vue {
  showMobileMenu = false;
  remindersModalView = false;
  showReminderOnboarding = false;

  mounted() {
    this.onUserStatus();
    this.onUserData();
  }

  beforeRouteUpdate(to, from, next) {
    this.showMobileMenu = false;
    next();
  }

  toggleMobileMenu() {
    this.showMobileMenu = !this.showMobileMenu;
  }

  async refetchUser() {
    this.onUserStatus();
  }

  toggleRemindersModalView(status) {
    this.remindersModalView = status;
  }

  toggleOnboarding(status) {
    this.showReminderOnboarding = status;
  }
}
