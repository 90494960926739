<!-- THIS COMPONENT HAS SOME CODE REUSED FROM THE OLD COMPONENT -->
<template>
  <div class="main-wrapper" v-if="content && content.size > 0">
    <BaseHeaderMenuStructure
      :showContent="!!selectedCategory"
      :contentTitle="contentTitle"
    >
      <template v-slot:mobileHeader>
        <div :class="['mobile-header', { '--hide': isDesktop }]">
          <span
            v-html="require('common/icons/Arrow.svg')"
            class="arrow-icon"
            @click="goBack()"
          />
          <span class="header-title">{{ selectedCategory || 'Regalos' }}</span>
        </div>
      </template>

      <template v-slot:selector>
        <div class="selector-wrapper">
          <div v-for="[categoryKey, categoryData] in content">
            <router-link
              v-if="isDesktop"
              :to="{
                name: 'products',
                query: {
                  card: 0,
                  filters: JSON.stringify({ boxCategory: selectedCategory }),
                },
              }"
              :class="[
                'category-item',
                { '--active': selectedCategory === categoryKey },
              ]"
              @mouseover.native="selectCategory(categoryKey)"
            >
              {{ categoryKey }}
            </router-link>
            <span
              v-else
              class="category-item"
              @click="selectCategory(categoryKey)"
              >{{ categoryKey }}
            </span>
          </div>
        </div>
      </template>

      <template v-if="isMobile" v-slot:mobileContent>
        <div class="mobile-content">
          <div v-for="box in currentCategoryBoxes">
            <router-link :to="box.product_url" class="card-content">
              <img class="image bb-mb-1" :src="box.product_image_url" />
              <span class="name">{{ box.name }}</span>
              <span class="price" v-if="!box.use_price_in_usd">{{
                box.price | currency
              }}</span>

              <span class="price" v-else>U$D {{ box.price_in_usd }}</span>
              <span
                v-if="box.discount && box.original_price"
                class="bb-ml-2 bb-line-through bb-description-3 bb-text-gray-light-1 original-price"
              >
                {{ box.original_price | currency }}
              </span>
            </router-link>
          </div>
        </div>
      </template>
      <template v-else v-slot:content>
        <div class="content-menu-wrapper">
          <div v-for="box in currentCategoryBoxes" class="boxiCont">
            <router-link class="boxi" :to="box.product_url">
              <div>
                <span class="font-figtree bb-hidden-sm-max boxName"
                  >{{ box.name }}<br
                /></span>
                <img
                  class="boxImage --mobile"
                  :src="optimizeImage(box.product_image_url, '90')"
                  alt="product image"
                />
              </div>

              <span class="price" v-if="!box.use_price_in_usd">{{
                box.price | currency
              }}</span>

              <span class="price" v-else>U$D {{ box.price_in_usd }}</span>
              <span
                v-if="box.discount && box.original_price"
                class="bb-ml-2 bb-line-through bb-description-3 bb-text-gray-light-1 original-price"
              >
                {{ box.original_price | currency }}
              </span>
              <div>
                <div class="boxHover bb-column bb-justify-content-center">
                  <img
                    class="boxImage --margin"
                    :src="optimizeImage(box.product_image_url, '101')"
                    alt="product image"
                  />
                  <span class="bb-bold participants">{{
                    box.participants
                  }}</span>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </template>
    </BaseHeaderMenuStructure>
  </div>
</template>

<script>
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import BaseHeaderMenuStructure from 'components/Header/BaseHeaderMenuStructure/BaseHeaderMenuStructure.vue';
  import HeaderIconCard from 'components/Header/HeaderIconCard/HeaderIconCard.vue';

  import getLastTld from 'common/services/utils/getLastTld';
  import optimizeImage from 'common/utils/optimizeImage';
  import useIsMobile from 'common/composables/useIsMobile.js';
  import useFilters from 'composables/useFilters';

  @Component({
    components: {
      BaseHeaderMenuStructure,
      HeaderIconCard,
    },
    props: {
      content: {
        required: true,
        type: Map,
        default: () => new Map(),
      },
      seeAllRoute: {
        type: Object,
      },
    },
    methods: {
      optimizeImage,
    },
    setup() {
      const { setBoxCategory } = useFilters();
      const { isMobile, isDesktop } = useIsMobile();

      return {
        setBoxCategory,
        isMobile,
        isDesktop,
      };
    },
  })
  export default class BaseHeaderMenuStructureComponent extends Vue {
    isArg = getLastTld() === 'ar';
    selectedCategory = null;

    get contentTitle() {
      const { routeName, routeQuery } = this.seeAllRoute;
      const seeAllRoute = {
        routeName: routeName,
        routeQuery: {
          ...routeQuery,
          filters: JSON.stringify({ boxCategory: this.selectedCategory }),
        },
        fn: null,
        arg: null,
      };
      return {
        title: this.selectedCategory,
        seeAllRoute: seeAllRoute,
      };
    }

    get currentCategoryBoxes() {
      return this.content.get(this.selectedCategory).boxes;
    }

    selectCategory(categoryName) {
      this.selectedCategory = categoryName;
    }

    goBack() {
      if (this.selectedCategory) {
        this.selectedCategory = null;
      } else {
        this.$emit('closeOpenedMenu');
      }
    }
  }
</script>

<style scoped lang="scss">
  $zIndex-box: 10;

  .main-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 0px 0px 8px 8px;
    background-color: white;

    .mobile-header {
      min-height: 56px;
      width: 100vw;
      padding: 24px;
      display: flex;
      align-items: center;
      background-color: #ffffff;

      @media (max-width: $screen-sm-max) {
        padding: 0 16px;
      }

      @media (min-width: $screen-sm-min) {
        padding: 0 24px;
      }

      &.--hide {
        display: none;
      }

      .arrow-icon {
        height: 24px;
        width: 24px;
        margin-right: 16px;
      }

      .header-title {
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
      }
    }

    .selector-wrapper {
      width: 250px;
      height: 100%;
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      background-color: #f8f8f8;

      @media (max-width: $screen-sm-max) {
        width: 100%;
        height: 100%;
        background-color: #ffffff;
      }

      .category-item {
        padding: 4px 24px;
        height: 24px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        transition: 0.2s;
        margin-bottom: 20px;
        color: $DARK-GRAY-2;

        @media (min-width: $screen-md-min) {
          &:hover,
          &.--active {
            color: $PRIMARY;

            &:before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              border-style: solid;
              border-width: 3px 3px 22px 3px;
              border-radius: 2px;
            }
          }

          &.--filters {
            &:hover,
            &.--active {
              &:before {
                color: $PRIMARY;
              }
            }
          }
        }

        @media (max-width: $screen-sm-max) {
          background-color: white;
          height: 62px;
          width: 100%;
          margin: 0;
        }
      }
    }

    .content-menu-wrapper {
      height: 420px;
      display: grid;
      grid-template-columns: repeat(5, 167px);
      grid-template-rows: repeat(7, 42.85px);
      grid-auto-flow: column;
      gap: 20px;

      @media (max-width: $screen-sm-max) {
        all: initial;
        overflow: scroll;
        height: 100svh !important;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
        grid-auto-rows: 110px;
        padding: 24px 20px 24px 20px;
        justify-content: center;
        gap: 20px;
        background-color: #ffffff;
      }
      .boxiCont {
        max-width: 167px;

        @media (max-width: $screen-sm-max) {
          max-width: 100px;
          max-height: 110px;
        }

        .boxName {
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
        }

        .boxi {
          position: relative;
          max-width: 167px;
          margin-bottom: 20px;
          z-index: $zIndex-box -1;
          cursor: pointer;

          @media (min-width: $screen-md-min) {
            &:hover {
              color: $PRIMARY;

              .boxHover {
                display: flex !important;
              }
            }
          }

          @media (max-width: $screen-sm-max) {
            display: flex;
            flex-direction: column;
            max-width: 100px;
          }

          .boxImage {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 101px;
            position: relative;
            width: 101px;

            &.--margin {
              margin: auto;
            }

            &.--mobile {
              display: none;

              @media (max-width: $screen-sm-max) {
                width: 90px;
                height: 90px;
                margin-left: 0;
                display: flex;
              }
            }
          }

          .boxHover {
            display: none;
            width: 127px;
            height: 137px;
            background-color: white;
            padding: 10px 5px 5px 5px;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
            position: absolute;
            left: 160%;
            transform: translateY(-50%);
            border-radius: 4px;
            z-index: $zIndex-box;

            &:before {
              content: '';
              position: absolute;
              top: 50px;
              left: -12px;
              border-style: solid;
              border-width: $MINI $MINI $MINI 0px;
              border-color: transparent $DARK-GRAY transparent transparent;
              filter: blur(3px);
              opacity: 0.2;
            }

            &:after {
              content: '';
              position: absolute;
              top: 45px;
              left: -10px;
              border-style: solid;
              border-width: 11px 11px 11px 0px;
              border-color: transparent #ffffff transparent transparent;
            }

            span {
              margin: auto;
              color: $DARK-GRAY;
              font-size: 14px;
            }
          }

          .participants {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 110px;
          }
        }
      }
    }
  }
  .mobile-content {
    display: grid;
    padding: 24px 12px;
    grid-template-columns: repeat(auto-fill, 16rem);
    column-gap: 12px;
    flex-direction: column;
    width: 100%;
    border: none;
    background-color: white;
    overflow: scroll;
    justify-content: space-around;

    .card-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 20px;
    }
    .image {
      height: 14rem;
      border-radius: 8px;
    }

    .name {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      margin-bottom: 8px;
    }

    .price {
      font-size: 15px;
      font-weight: 400;
      line-height: 19px;
    }
  }
</style>
