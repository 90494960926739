import apolloClient from 'utils/apolloClient';
import OnlyOnBigBoxQuery from 'assets/graphql/queries/OnlyOnBigBoxActivities.graphql';

const onlyOnBigBoxService = {
  getOnlyInBigBox: async (per_page = 4, page = 1) => {
    try {
      const { data } = await apolloClient.query({
        query: OnlyOnBigBoxQuery,
        variables: {
          per_page,
          page,
        },
      });
      return data?.only_on_bigbox_activities;
    } catch (e) {
      throw new Error('Error fetching only on big box products');
    }
  },
};

export default onlyOnBigBoxService;
