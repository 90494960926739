import { reactive, toRefs, onMounted, onUnmounted } from 'vue';

export default function useIsMobile() {
  const state = reactive({
    isMobile: false,
    isTablet: false,
    isDesktop: false,
  });

  const updateBreakpoint = () => {
    const screenWidth = window.innerWidth;
    state.isMobile = screenWidth <= 768;
    state.isTablet = screenWidth > 768 && screenWidth < 992;
    state.isDesktop = screenWidth >= 992;
  };

  onMounted(() => {
    updateBreakpoint();
    window.addEventListener('resize', updateBreakpoint);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateBreakpoint);
  });

  return toRefs(state);
}
