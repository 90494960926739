import { render, staticRenderFns } from "./CreditAdvice.vue.html?vue&type=template&id=1f902532&"
import script from "./CreditAdvice.component.js?vue&type=script&lang=vueJs&"
export * from "./CreditAdvice.component.js?vue&type=script&lang=vueJs&"
import style0 from "./CreditAdvice.scss?vue&type=style&index=0&prod&lang=vueScss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports