import { render, staticRenderFns } from "./BaseHeader.vue.html?vue&type=template&id=32834a8e&scoped=true&"
import script from "./BaseHeader.component.js?vue&type=script&lang=vueJs&"
export * from "./BaseHeader.component.js?vue&type=script&lang=vueJs&"
import style0 from "./BaseHeader.scoped.scss?vue&type=style&index=0&id=32834a8e&prod&lang=vueScss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32834a8e",
  null
  
)

export default component.exports