import { ref, onBeforeMount } from 'vue';

export default function useKustomerBotHelpers() {
  const kustomerElement = ref(null);

  const getKustomerElement = () => {
    kustomerElement.value = document.getElementById('kustomer-ui-sdk-iframe');
  };

  const hideKustomer = () => {
    if (kustomerElement.value)
      kustomerElement.value.classList.add('kustomer-iframe-hide');
  };

  const showKustomer = () => {
    if (kustomerElement.value)
      kustomerElement.value.classList.remove('kustomer-iframe-hide');
  };

  const moveUpwardsKustomer = () => {
    if (kustomerElement.value)
      kustomerElement.value.classList.add('kustomer-iframe-slide-upwards');
  };

  const removeUpwardsSlideKustomer = () => {
    if (kustomerElement.value)
      kustomerElement.value.classList.remove('kustomer-iframe-slide-upwards');
  };

  onBeforeMount(() => {
    getKustomerElement();
  });

  return {
    hideKustomer,
    showKustomer,
    moveUpwardsKustomer,
    removeUpwardsSlideKustomer,
  };
}
