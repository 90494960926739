import Vue from 'vue';
import Component from 'vue-class-component';
import RemindersRegalendario from 'assets/graphql/queries/RemindersList.graphql';
import getLastTld from 'common/services/utils/getLastTld';

@Component({
  props: {
    step: {
      required: true,
    },
  },
  apollo: {
    reminders: RemindersRegalendario,
  },
})
export default class PopoverRegalendarioComponent extends Vue {
  titles = [
    `¡${this.$translate('Ganá')} 10% de descuento agregando 3 recordatorios!`,
    '¡Estás a solo dos pasos de obtener tu 10% de descuento!',
    '¡Estás a solo un paso de obtener tu 10% de descuento!',
  ];

  texts = [
    'Nunca más te olvides de una fecha especial.',
    `${this.$translate(
      'Agregá',
    )} 2 recordatorios más y llevate el descuento para tu próxima compra.`,
    `${this.$translate(
      'Agregá',
    )} 1 recordatorio más y llevate el descuento para tu próxima compra.`,
  ];
  isSpain = getLastTld() === 'es';

  ctas = [
    `${this.$translate('Agregá')}`,
    'Añadir recordatorios',
    'Añadir recordatorio',
  ];

  closePopover() {
    this.$emit('closePopover');
  }
  goToRegalendario() {
    this.closePopover();
    this.$router.push({
      name: 'regalendario',
      params: { openAddReminderModalFromHome: true },
    });
  }
}
