import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

// const default_sentry_dsn = "www.ecommerce-sentry.bigbox.com.ar";
const default_tracingOrigins = [
    "bigbox.com.ar",
    "bigbox.com.uy",
    "bigbox.cl",
    "bigbox.com.mx",
    "bigbox.es",
    "bigbox.com.pe",
    "bigbox.com.co",
];

export default function sentryInit(sentry_key, sentry_id, Vue, router) {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      Vue,      
      dsn: `https://${sentry_key}@sentry.bigbox.ar/${sentry_id}`,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: default_tracingOrigins,
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }
}
