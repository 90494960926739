<template>
  <div class="main-wrapper">
    <BaseHeaderMenuStructure showContent :contentTitle="contentTitle">
      <template v-slot:content>
        <div :class="['mobile-header', { '--hide': isDesktop }]">
          <span
            v-html="require('common/icons/Arrow.svg')"
            class="arrow-icon"
            @click="goBack()"
          />
          <span class="header-title">Nuevos ✨</span>
        </div>
        <div class="content-menu-wrapper">
          <header-icon-card
            v-for="(card, index) in content"
            :cardContent="card"
            icon="star"
            class="card"
          />
        </div>
      </template>
    </BaseHeaderMenuStructure>
  </div>
</template>

<script>
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import BaseHeaderMenuStructure from 'components/Header/BaseHeaderMenuStructure/BaseHeaderMenuStructure.vue';
  import HeaderIconCard from 'components/Header/HeaderIconCard/HeaderIconCard.vue';

  import useIsMobile from 'common/composables/useIsMobile.js';
  import useFilters from 'composables/useFilters';

  @Component({
    props: {
      content: {
        type: Array,
        default: () => [],
      },
      seeAllRoute: {
        type: Object,
      },
    },
    components: {
      BaseHeaderMenuStructure,
      HeaderIconCard,
    },
    setup() {
      const { setBoxCategory, handleChangeFilters } = useFilters();

      const { isDesktop } = useIsMobile();

      return {
        isDesktop,
        setBoxCategory,
        handleChangeFilters,
      };
    },
  })
  export default class BaseHeaderMenuStructureComponent extends Vue {
    get contentTitle() {
      return {
        title: 'Lo más nuevo ✨',
        seeAllRoute: {
          ...this.seeAllRoute,
        },
      };
    }

    goBack() {
      this.$emit('closeOpenedMenu');
    }
  }
</script>

<style scoped lang="scss">
  .main-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 0px 0px 8px 8px;
    background-color: white;

    .mobile-header {
      min-height: 56px;
      width: 100vw;
      display: flex;
      align-items: center;

      @media (max-width: $screen-sm-max) {
        padding: 0 16px;
      }

      @media (min-width: $screen-sm-min) {
        padding: 0 24px;
      }

      &.--hide {
        display: none;
      }

      .arrow-icon {
        height: 24px;
        width: 24px;
        margin-right: 16px;
      }

      .header-title {
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
      }
    }

    .content-menu-wrapper {
      display: flex;
      flex-wrap: column nowrap;
      height: 100%;
      width: 100%;

      @media (max-width: $screen-sm-max) {
        display: grid;
        grid-template-columns: repeat(2, min-content);
        row-gap: 24px;
        column-gap: 16px;
        justify-content: center;
        padding: 24px 0 24px 0;
        overflow: scroll;
      }

      .card {
        margin-right: 24px;

        &:last-child {
          margin-right: 0;
        }

        @media (max-width: $screen-sm-max) {
          margin: 0;
        }
      }
    }
  }
</style>
