import getLastTld from 'common/services/utils/getLastTld';

export const getCorpoUrl = () => {
  const country = getLastTld();
  switch (country) {
    case 'ar':
      return 'https://bigboxcorpo.com/ar?utm_source=bigbox&utm_medium=referral&utm_campaign=bigbox';
    case 'es':
      return 'https://bigboxcorpo.com/ar?utm_source=bigbox&utm_medium=referral&utm_campaign=bigbox';
    case 'uy':
      return 'https://bigboxcorpo.com/uy?utm_source=bigbox&utm_medium=referral&utm_campaign=bigbox';
    case 'pe':
      return 'https://bigboxcorpo.com/pe?utm_source=bigbox&utm_medium=referral&utm_campaign=bigbox';
    case 'mx':
      return 'https://bigboxcorpo.com/mx?utm_source=bigbox&utm_medium=referral&utm_campaign=bigbox';
    case 'cl':
      return 'https://bigboxcorpo.com/cl?utm_source=bigbox&utm_medium=referral&utm_campaign=bigbox';
  }
};
