<template>
  <div class="main-wrapper">
    <router-link
      :to="{ name: 'account' }"
      class="menu-header"
      @click.native="closeModal()"
    >
      <div class="header-content">
        <img
          class="user-image --image"
          v-if="user.profile_image"
          :src="user.profile_image"
        />
        <span v-else class="user-image --default" v-html="defaultUserIcon" />
        <span class="user-name">
          {{ fullName }}
        </span>
      </div>
    </router-link>
    <ul class="bb-column" @click="closeModal()">
      <li>
        <router-link :to="{ name: 'wishlist' }" class="menu-item">
          Mis favoritos
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'accountGifts' }" class="menu-item">
          <span class="bb-mr-1">Regalos recibidos</span>
          <NotificationCircle v-if="user.has_unused_gifts" />
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'accountPurchases' }" class="menu-item">
          Compras realizadas
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'accountCredit' }" class="menu-item">
          Crédito
          <span v-if="user.credit_amount > 0" class="bb-text-primary bb-bold">
            ({{ user.credit_amount | currency }})
          </span>
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'regalendario' }" class="menu-item">
          Regalendario
        </router-link>
      </li>
      <li>
        <a @click="logout()" class="menu-item">Cerrar sesión</a>
      </li>
    </ul>
  </div>
</template>

<script>
  import Vue from 'vue';
  import Component from 'vue-class-component';

  import useAccount from 'composables/useAccount';
  import BbModal from 'common/components/BbModal';
  import NotificationCircle from 'components/NotificationCircle/NotificationCircle.vue';

  import useIsMobile from 'common/composables/useIsMobile.js';
  import userIcon from 'common/icons/User3.svg';

  @Component({
    props: {
      showContent: {
        type: Boolean,
      },
      user: {
        type: Object,
      },
    },
    components: {
      BbModal,
      NotificationCircle,
    },
    setup() {
      const { onLogout } = useAccount();
      const { isMobile } = useIsMobile();

      return {
        onLogout,
        isMobile,
      };
    },
  })
  export default class HeaderUserMenu extends Vue {
    defaultUserIcon = userIcon;

    closeModal() {
      this.$emit('close');
    }

    get fullName() {
      if (!this.user) return '';

      const { first_name, last_name } = this.user;
      if (!last_name) return first_name;

      return `${first_name} ${last_name}`;
    }

    async logout() {
      const { data } = await this.onLogout();
      const { logout } = data;
      if (!logout) return;
      this.$emit('refetchUser');
      this.$router.push({ name: 'login' });
    }
  }
</script>

<style scoped lang="scss">
  .main-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 291px;
    background-color: white;
    border-radius: 4px;
    overflow: hidden;

    @media (max-width: $screen-sm-max) {
      all: initial;
      flex-direction: column;
      position: relative;
    }
  }
  .menu-header {
    height: 56px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;

    @media (min-width: $screen-sm-min) {
      padding: 12px 24px;
    }
  }

  .header-content {
    display: flex !important;
    align-items: center;
    width: 100%;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  .user-image {
    width: 32px;
    height: 32px;
    display: flex;
    margin-right: 8px;
    border-radius: 50%;

    &.--image {
      background: no-repeat center;
      background-size: cover;
    }

    &.--default {
      padding: 4px;
      border: 1px solid #e0e0e0;
    }
  }

  .user-name {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;

    @media (max-width: $screen-sm-max) {
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      color: $DARK-GRAY-2;
    }
  }

  .menu-item {
    display: flex;
    padding: 12px 0 12px 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: $DARK-GRAY-2;
    align-items: center;

    &:hover {
      background-color: #f5f5f5;
    }

    @media (max-width: $screen-sm-max) {
      padding: 16px;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
    }

    @media (min-width: $screen-sm-min) {
      padding: 16px 24px;
    }
  }

  li {
    &:last-child {
      border-top: 1px solid #e0e0e0;

      @media (max-width: $screen-sm-max) {
        border: none;
      }
    }
  }
</style>
