<template>
  <div
    class="search-bar relative"
    :class="{ '--search-bar-spain': isSpain, 'is-focus': searchBarIsFocus }"
  >
    <form
      method="GET"
      class="form bb-mr-5"
      :action="$router.resolve({ name: 'products' }).href"
      @submit.prevent="searchSubmitted()"
      :class="{ 'bb-ml-6': isSpain }"
    >
      <div
        class="bb-control search-bar-container bb-row bb-justify-content-end"
        :class="{
          '--spainWidth': isSpain && (!searchBarIsFocus || $screen.width > 991),
        }"
      >
        <input
          type="search"
          autocomplete="off"
          name="keywords"
          aria-label="search-text"
          v-model="searchValue"
          ref="searchInput"
          :placeholder="placeholder"
          class="bb-input search-input"
          :class="{ 'figtree-font': isSpain }"
          @input="event => (searchValue = event.target.value)"
          @focus="openOptions()"
        />
        <button
          type="submit"
          v-html="searchIcon"
          class="btn-search"
          @click="openOptions()"
          aria-label="search-button"
        ></button>
      </div>
      <div
        v-html="closeIcon"
        v-if="searchBarIsFocus"
        @click="closeOptions()"
        class="--close-icon bb-hidden-lg"
      ></div>
      <search-options
        v-if="searchBarIsFocus"
        :isPredictive="isPredictive"
        :suggestionsResults="suggestionsResults"
        :activitiesSearchResults="activitiesSearchResults"
        :boxSearchResults="boxSearchResults"
        :searchValue="searchValue"
        :loading="isLoading"
        :isSpain="isSpain"
        @searchSubmitted="suggestionSearch(searchValue)"
        @suggestionSearch="suggestionSearch($event)"
        @closeOptions="closeOptions"
      ></search-options>
    </form>
    <div
      @click="closeOptions('background')"
      v-if="searchBarIsFocus"
      class="black-background fixed"
    ></div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import SearchOptions from 'components/SearchOptions/SearchOptions.vue';
  import { sendAnalyticsEvent } from 'common/utils/sendAnalyticsEvent';
  import getLastTld from 'common/services/utils/getLastTld';
  import searchIcon from 'common/icons/Search.svg';
  import closeGrayIcon from 'assets/icons/close_gray.svg';

  import ElasticSearchEmpty from 'assets/graphql/queries/ElasticSearchEmpty.graphql';
  import PredictiveElasticSearch from 'assets/graphql/queries/PredictiveElasticSearch.graphql';
  import useAccount from 'composables/useAccount';
  import useFilters from 'composables/useFilters';
  import { Watch } from 'vue-property-decorator';

  @Component({
    components: {
      SearchOptions,
    },
    props: {
      placeholder: {
        type: String,
        required: true,
      },
      user: {
        type: Object,
      },
    },
    apollo: {
      elastic_search_empty: {
        query: ElasticSearchEmpty,
        result({ data }) {
          this.suggestionsResults = data.elastic_search_empty.suggestions;
          this.boxSearchResults = data.elastic_search_empty.boxes;
        },
      },
    },
    setup() {
      const { getUserData } = useAccount();
      const { clearCurrentFilters } = useFilters();
      return {
        clearCurrentFilters,
        getUserData,
      };
    },
  })
  export default class SearchBarComponent extends Vue {
    searchValue = '';
    isSpain = getLastTld() === 'es';
    searchIcon = searchIcon;
    closeIcon = closeGrayIcon;
    searchBarIsFocus = false;
    isLoading = false;

    boxSearchResults = '';
    suggestionsResults = '';
    activitiesSearchResults = '';
    isPredictive = false;

    searchSubmitted() {
      if (!this.searchValue) return;
      sendAnalyticsEvent('search', undefined, {
        searchTerm: this.searchValue,
        userData: {
          email: this.email,
        },
      });
      if (this.$route.name !== 'products') {
        this.redirectToProducts(this.searchValue);
      } else {
        this.replaceRouter(this.searchValue);
      }
      this.searchValue = '';
      this.closeOptions();
    }
    async suggestionSearch(suggestion) {
      this.searchValue = suggestion;
      this.searchSubmitted();
      this.closeOptions();
      await this.predictiveSearch(suggestion);
    }

    redirectToProducts(keywords) {
      this.$router.push({
        name: 'products',
        query: {
          card: 0,
          keywords,
        },
      });
    }

    replaceRouter(keywords) {
      this.$router.replace({
        name: 'products',
        query: { ...this.$route.query, keywords },
      });
    }

    /**
     * It set boxSearchResults and activitiesSearchResults obtained form elastic search
     * If its mobile version it slices the arrays
     * If param value is empty executes the empty elastic query
     */
    @Watch('searchValue')
    async predictiveSearch() {
      this.isLoading = true;
      if (this.searchValue === '') {
        this.clearCurrentFilters();
        this.isPredictive = false;
        await this.$apollo.queries.elastic_search_empty.refetch();
      } else {
        this.isPredictive = true;
        const { data } = await this.$apollo.query({
          query: PredictiveElasticSearch,
          variables: {
            query: this.searchValue,
          },
        });
        this.activitiesSearchResults =
          data.predictive_elastic_search.activities;
        this.boxSearchResults = data.predictive_elastic_search.boxes;
      }
      this.isLoading = false;
    }

    openOptions() {
      this.searchBarIsFocus = true;
      this.removeScroll();
      this.focusInputText();
    }

    closeOptions(context = 'other') {
      this.searchValue = '';
      if (context === 'other' || (context === 'background' && !this.isMobile)) {
        this.searchBarIsFocus = false;
        this.addScroll();
      }
    }
    removeScroll() {
      /**
       * Don't change the order or it won't work
       */
      window.document.body.style.top = `-${window.scrollY}px`;
      window.document.body.style.position = 'fixed';
    }

    addScroll() {
      const scrollY = window.document.body.style.top;
      window.document.body.style.position = '';
      window.document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    }

    focusInputText() {
      setTimeout(
        () => {
          this.$refs.searchInput.focus();
        },
        this.isMobile ? 100 : 0,
      );
    }

    get isMobile() {
      return this.$screen.width < 768;
    }

    get email() {
      return this.getUserData?.email || '';
    }
  }
</script>

<style lang="scss" scoped>
  @import '~assets/styles/index.scss';

  .search-bar {
    @media (max-width: $screen-md-min) {
      text-align: right;
      width: 75%;
      transition: width 0.25s ease-in-out;

      @media (max-width: $screen-sm-min) {
        width: 60%;
      }

      @media (max-width: $screen-xs-min) {
        width: 30%;
      }
    }

    .form {
      z-index: $HEADER-Z-INDEX + 3;
      display: inline-flex;
      position: relative;

      @media (max-width: $screen-sm-max) {
        flex-direction: row-reverse;
        margin-right: 0;
        margin-left: auto;
        width: auto;
        padding-right: 0px;
      }

      .search-bar-container {
        width: 344px;
        height: 40px;

        &.--spainWidth {
          width: 250px;
        }

        @media (max-width: $screen-sm-max) {
          width: 100%;
        }

        @media (max-width: $screen-md-min) {
          border-radius: 50%;

          .search-input {
            display: none;
          }

          .btn-search {
            width: 16px;
            height: 16px;
            position: relative;
            margin-top: 3px;

            &:deep(svg) {
              height: 16px;
              width: 16px;
            }
          }
        }

        input {
          height: 40px;
          width: 550px;
          border-radius: 10px;
          border-color: $GRAY-LIGHT;
          font-size: 14px;
          min-height: 0;
          padding: 11px 55px 11px 15px;
          transition: width 0.3s;
          color: #222222;

          @media (max-width: $screen-md-max) {
            width: 400px;
          }

          @media (max-width: $screen-sm-max) {
            width: 100%;
          }

          @media (max-width: $screen-xs-min) {
            padding: 0 $MINI;
            &:focus {
              border-color: $PRIMARY;
            }
          }

          &::placeholder {
            color: $MID-GRAY;

            @media (max-width: $screen-xs-min) {
              font-size: 14px;
            }
          }
        }

        .btn-search {
          width: 32px;
          height: 100%;
          background-color: transparent;
          border: none;
          position: absolute;
          top: 0px;
          right: 16px;
          display: flex;
          align-items: center;

          &:hover {
            cursor: pointer;
          }

          &:deep(svg) {
            height: 18px;
            color: $PRIMARY;

            @media (max-width: $screen-md-min) {
              height: 14px;
            }
          }

          @media (max-width: $screen-md-min) {
            padding: $MINI;
          }
        }
      }
    }

    &.--search-bar-spain {
      @media (max-width: $screen-md-min) {
        opacity: 0;
      }

      .form {
        margin-right: 0px;

        input {
          font-size: 16px;
          padding: 10px 55px 13px 18px;
        }

        .search-bar-container {
          .btn-search {
            justify-content: center;

            & svg {
              color: $DARK-GRAY;
            }
          }
        }
      }
    }

    &.is-focus {
      @media (max-width: $screen-md-min) {
        position: absolute;
        width: 100%;
        padding: 0 10px;
        right: 0;
        z-index: 2;
        top: 14px;
        opacity: 1;

        .form {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          .--close-icon {
            width: 14px;
            height: 14px;
          }
          .search-bar-container {
            width: 92%;
            margin-right: 12px;
            left: 0;
            align-self: flex-start;
          }

          @media (max-width: $screen-md-min) {
            .search-input {
              display: block;
            }
          }
        }

        .bb-input {
          height: 48px;
        }
      }

      .btn-search {
        @media (max-width: $screen-md-min) {
          margin-left: 79vw;
        }
      }
    }

    .black-background {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: $HEADER-Z-INDEX + 2;
      @media screen and (max-width: $screen-md-min) {
        background-color: white;
      }
    }
  }
</style>
