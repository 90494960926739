<template>
  <div class="CartBlock" :class="{ '--spain': isSpain }">
    <div
      class="bb-cursor-pointer bb-row bb-align-items-center"
      @click="toggleCart()"
    >
      <span class="relative">
        <span
          class="cart-icon bb-row bb-align-items-center"
          :class="{
            '--home-spain': isSpain && $route.name === 'home',
            'bb-ml-5': isSpain && $screen.width > 991,
          }"
          v-html="icon"
        />
        <span class="counter" v-if="cart && cart.items.length">
          {{ cart.items.length }}
        </span>
      </span>
    </div>
    <transition name="menu">
      <div
        class="desktop-menu --cart"
        :class="{ '--spain': isSpain }"
        v-if="openCartMenu"
        v-click-outside="onClickOutside"
      >
        <div class="containerMenu --cart bb-hidden-sm-max">
          <base-header-cart-menu
            :orderId="cart && cart.signed_id"
            :products="groupedProducts"
            :cartIsLoading="cartIsLoading"
            :user="user"
            :siteConfig="siteConfig"
            @toggleCart="toggleCart"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import outSideClick from 'directives/outSideClick';
  import getLastTld from 'common/services/utils/getLastTld';
  import CartIcon from 'common/icons/Cart.svg';
  import SiteConfigQuery from 'assets/graphql/queries/SiteConfig.graphql';

  Vue.directive('outSideClick', outSideClick);

  @Component({
    components: {
      BaseHeaderCartMenu: () =>
        import(
          /* webpackChunkName: "headerCartMenu", webpackPrefetch: true */ 'components/Header/BaseHeaderCartMenu'
        ),
    },
    props: {
      user: {
        type: Object,
      },
      cart: {
        type: Object,
        default: () => {},
      },
      cartIsLoading: {
        type: Boolean,
        required: true,
      },
      showCartMenu: {
        type: Boolean,
        required: false,
      },
    },
    apollo: {
      siteConfig: {
        query: SiteConfigQuery,
      },
    },
    directives: {
      clickOutside: outSideClick.directive,
    },
  })
  export default class CartBlock extends Vue {
    showCart = false;
    clickOutside = false;
    isSpain = getLastTld() === 'es';
    icon = CartIcon;

    toggleCart() {
      if (this.isMobile) {
        this.$router.push({ name: 'cart' });
      } else {
        this.showCart = !this.showCart;
        this.clickOutside = true;
      }
      this.$emit('toggleCartMenu');
    }

    onClickOutside() {
      this.showCart = false;
      this.clickOutside = false;
    }

    get isMobile() {
      return this.$screen.width < 991;
    }

    get groupedProducts() {
      if (this.cart) {
        const group = this.cart.items.reduce((cartItems, item) => {
          cartItems[item.product.id] = [
            ...(cartItems[item.product.id] || []),
            item,
          ];
          return cartItems;
        }, {});
        return 'group', Object.values(group);
      }
      return [];
    }

    get openCartMenu() {
      if (!this.isSpain) {
        return this.showCartMenu && this.clickOutside;
      }
      return this.showCart && this.clickOutside;
    }
  }
</script>

<style lang="scss" scoped>
  @import '~assets/styles/index.scss';

  .CartBlock {
    height: 24px;
    width: 24px;
    position: relative;

    @media (max-width: $screen-md-min) {
      margin-top: 2px;
      border: none;
    }

    .cart-icon {
      height: 24px;
      color: $DARK-GRAY;
    }

    .counter {
      position: absolute;
      top: -10px;
      right: -10px;
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      text-align: center;
      font-size: 14px;
      color: $WHITE;
      background-color: $PRIMARY;
    }

    .desktop-menu {
      position: absolute;
      background-color: white;
      z-index: $HEADER-Z-INDEX + 2;
      right: -53px;
      margin-top: 21px;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
      border-radius: 4px;

      &.v-enter,
      &.v-leave-active {
        opacity: 0;
      }
    }

    &.--spain {
      .cart-icon {
        ::v-deep svg {
          width: 16px;
          height: 22px;
          fill: $BLACK;
        }

        &.--home-spain {
          ::v-deep svg {
            fill: $WHITE !important;
          }
        }
      }

      .desktop-menu {
        right: -58px;
      }
    }
  }
</style>
