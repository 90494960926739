<script setup>
  import BaseHeaderMenuStructure from 'components/Header/BaseHeaderMenuStructure/BaseHeaderMenuStructure.vue';
  import useIsMobile from 'common/composables/useIsMobile';
  import OnlyOnBigBoxCard from './OnlyOnBigBoxCard.vue';

  const props = defineProps({
    content: {
      type: Array,
      default: () => [],
    },
    seeAllRoute: {
      type: Object,
    },
  });

  const emit = defineEmits(['closeOpenedMenu']);

  const { isDesktop } = useIsMobile();

  function goBack() {
    emit('closeOpenedMenu');
  }

  const contentTitle = {
    title: 'Solo en Bigbox 💎',
    seeAllRoute: {
      ...props.seeAllRoute,
    },
  };
</script>

<template>
  <base-header-menu-structure :showContent="true" :contentTitle="contentTitle">
    <template v-slot:content>
      <div :class="['mobile-header', { '--hide': isDesktop }]">
        <span
          v-html="require('common/icons/Arrow.svg')"
          class="arrow-icon"
          @click="goBack"
        />
        <span class="header-title">Solo en Bigbox 💎</span>
      </div>
      <div class="content-menu-wrapper">
        <only-on-big-box-card
          v-for="(product, index) in content"
          :key="product.id"
          :name="product.name"
          :img="product.product_image_url"
          :discount-percentage="product.discount_percentage"
          :price-without-discount="product.price_without_discount"
          :price="product.price"
          :url="product.product_url"
          :class="['bb-sm-max-mr-0', { 'bb-mr-5': index < content.length - 1 }]"
        />
      </div>
    </template>
  </base-header-menu-structure>
</template>

<style scoped lang="scss">
  .main-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 0px 0px 8px 8px;
    background-color: white;

    .mobile-header {
      min-height: 80px;
      width: 100vw;
      padding: 24px;
      display: flex;
      align-items: center;

      &.--hide {
        display: none;
      }

      .arrow-icon {
        height: 24px;
        width: 24px;
        margin-right: 16px;
      }

      .header-title {
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
      }
    }

    .content-menu-wrapper {
      display: flex;
      flex-wrap: nowrap;
      height: 100%;
      width: 100%;

      @media (max-width: $screen-sm-max) {
        display: grid;
        grid-template-columns: repeat(2, min-content);
        grid-template-rows: repeat(2, min-content);
        row-gap: 24px;
        column-gap: 16px;
        justify-content: center;
        padding-top: 24px;
      }
    }
  }
</style>
