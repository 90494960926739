<template>
  <div
    class="navbar-user bb-row bb-align-items-center bb-justify-content-around"
  >
    <router-link v-if="!user" :to="{ name: 'login' }">
      <span class="user-icon" v-html="userIcon" />
    </router-link>
    <div
      v-else
      class="user"
      :style="{
        color: isSpain && $route.name === 'home' ? '#FFFFFF' : '#464646',
      }"
    >
      <NotificationCircle
        v-if="user.has_unused_gifts"
        class="notification-position"
      />
      <span @click="toggleMenu()" class="user-icon" v-html="userIcon" />
      <transition name="menu">
        <div v-if="showMenu" v-click-outside="toggleMenu">
          <mobile-header-user-menu
            v-if="isTablet || isMobile"
            :user="user"
            class="mobile-user-menu"
            @refetchUser="refetchUser()"
            @close="toggleMenu"
          />
          <header-user-menu
            v-else
            :user="user"
            class="desktop-user-menu"
            @refetchUser="refetchUser()"
            @close="toggleMenu()"
          />
        </div>
      </transition>
    </div>
    <credit-advice
      v-if="creditAdviceFlag && userCreditAmount"
      :user="user"
      :userCreditAmount="userCreditAmount"
      @closedCreditAdvice="closeCreditAdvice()"
      v-click-outside="closeCreditAdvice"
    >
    </credit-advice>
  </div>
</template>
<script>
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import User3 from 'common/icons/User3.svg';
  import outSideClick from 'directives/outSideClick';
  import CreditAdvice from 'components/CreditAdvice/CreditAdvice.vue';
  import useIsMobile from 'common/composables/useIsMobile.js';
  import BbModal from 'common/components/BbModal';
  import HeaderUserMenu from 'components/Header/HeaderUserMenu/HeaderUserMenu.vue';
  import MobileHeaderUserMenu from 'components/Header/MobileHeaderUserMenu/MobileHeaderUserMenu.vue';
  import NotificationCircle from '../NotificationCircle/NotificationCircle.vue';

  Vue.directive('outSideClick', outSideClick);

  @Component({
    components: {
      HeaderUserMenu,
      CreditAdvice,
      BbModal,
      MobileHeaderUserMenu,
      NotificationCircle,
    },
    props: {
      user: {
        type: Object,
      },
      isSpain: {
        type: Boolean,
        default: false,
      },
    },
    directives: {
      clickOutside: outSideClick.directive,
    },
    setup() {
      const { isTablet, isMobile } = useIsMobile();

      return {
        isTablet,
        isMobile,
      };
    },
  })
  export default class NavbarUserMenuComponent extends Vue {
    userIcon = User3;
    showMenu = false;
    userCreditAmount = Math.trunc(parseInt(this.user?.credit_amount));
    creditAdviceFlag =
      window.localStorage.getItem('creditAdviceFlag') === 'true';

    closeCreditAdvice() {
      this.creditAdviceFlag = false;
      window.localStorage.setItem('creditAdviceFlag', 'false');
    }

    onClickOutside() {
      this.closeCreditAdvice();
    }

    refetchUser() {
      this.$emit('refetchUser');
    }

    toggleMenu() {
      this.showMenu = !this.showMenu;
    }
  }
</script>

<style lang="scss" scoped>
  @import '~assets/styles/index.scss';

  .navbar-user {
    position: relative;

    .user-icon {
      display: block;
      height: 24px;
      width: 24px;
    }

    .user {
      height: 24px;
      width: 24px;
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      .notification-position {
        position: absolute;
        top: -1px;
        right: 0;
      }

      .profile-img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: no-repeat center;
        background-size: cover;
      }

      .desktop-user-menu {
        position: absolute;
        top: 44px;
        right: -48px;
        z-index: $HEADER-Z-INDEX + 2;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
      }

      .mobile-user-menu {
        z-index: $HEADER-Z-INDEX + 3;
      }
    }
    .menu-enter-active {
      @media (min-width: $screen-md) {
        transition: opacity 0.4s;
      }
    }

    .menu-leave-active {
      @media (min-width: $screen-md) {
        transition: opacity 0s;
      }
    }

    .menu-enter {
      @media (min-width: $screen-md) {
        opacity: 0;
      }
    }
  }
</style>
