import shoppingBag from 'assets/icons/shopping-bag.svg';
import gift from 'assets/icons/Gift.svg';
import cash from 'common/icons/Cash.svg';
import group from 'assets/icons/groupBuyers.svg';
import heart from 'common/icons/Heart.svg';
import calendar from 'assets/icons/calendar_bold.svg';

let icons = {
  shoppingBag,
  gift,
  cash,
  group,
  heart,
  calendar,
};

export default [
  {
    name: 'Regalos recibidos',
    to: 'accountGifts',
    enabled: true,
    icons: {
      icon: icons.gift,
      class: 'gift-icon',
    },
  },
  {
    name: 'Compras realizadas',
    to: 'accountPurchases',
    enabled: true,
    icons: {
      icon: icons.shoppingBag,
      class: 'shopping-icon',
    },
  },
  {
    name: 'Compras grupales',
    to: 'groups',
    enabled: false,
    icons: {
      icon: icons.group,
      class: 'group-icon',
    },
  },
  {
    name: 'Favoritos',
    to: 'wishlist',
    enabled: false,
    icons: {
      icon: icons.heart,
      class: 'heart-icon',
    },
  },
  {
    name: 'Crédito',
    to: 'accountCredit',
    enabled: true,
    icons: {
      icon: icons.cash,
      class: 'cash-icon',
    },
  },
  {
    name: 'Regalendario',
    to: 'regalendario',
    enabled: true,
    icons: {
      icon: icons.calendar,
      class: 'calendar-icon',
    },
  },
  {
    name: 'Puntos Bigbox',
    to: 'accountPoints',
    enabled: false,
    // add an icon when enable
  },
];
