const commonMixin = { beforeCreate: commonMixinInit };

export default commonMixin;


function commonMixinInit() {
  const store = this.$store;
  if (store) {
    const { tld } = store;
    this.TLD = tld;
    this.IS_AR = tld === 'ar';
    this.IS_PE = tld === 'pe';
    this.IS_UY = tld === 'uy';
    this.IS_CL = tld === 'cl';
    this.IS_MX = tld === 'mx';
  }
}
