import getCurrencyCode from 'common/utils/getCurrencyCode';

const createItem = (item, quantity) => {
  const isBox = item.__typename === 'BoxType';
  const ItemInUSD = isBox && item.use_price_in_usd;
  const newItem = {
    item_id: item.id,
    item_name: item.name,
    affiliation: 'Bigbox',
    price: ItemInUSD ? item.price_in_usd : item.price,
    currency: getCurrencyCode(ItemInUSD),
    quantity: item.quantity || quantity,
  };
  // To avoid sending undefined data
  item.extraInfo && (newItem.extraInfo = item.extraInfo);

  return newItem;
};

const createAnalyticsParameters = (items, quantity) => {
  const params = {
    items: items.map(item => {
      return createItem(item, quantity);
    }),
  };

  return params;
};

/**
 * Send events with params to GTM. For more information
 * please read https://wiki.bigbox.com.ar/doc/google-tag-manager-datalayer-b0Y4scrcyN
 * @param {String} event event name
 * @param {Object} params parameters needed in the event
 * @param {Object} customData Info non related to the item
 */
export const sendAnalyticsEvent = (event, params, customData) => {
  if (process.env.ENVIRONMENT === 'prod') {
    let dataLayerData = {
      event,
      currency: params?.items[0].currency,
      price:
        customData?.price ||
        params?.items.reduce((acc, item) => (acc += parseInt(item.price)), 0),
      ...params,
    };
    if (customData) {
      dataLayerData = { ...dataLayerData, ...customData };
    }

    /* eslint-disable-next-line */
    dataLayer?.push(dataLayerData);
  }
};

/**
 *
 * @param {String} event event name
 * @param {Array<Object>} items Required attributes in description
 * @param {Number} quantity How many items?
 * @param {Object} customData Info non related to the item
 * - id -name - price
 * and if Box also:
 * __typename - if USD: use_price_in_usd - price_in_usd
 * If you need to send more data, you can create an object called "extraInfo"
 * inside each item like this:
 * items [{id:1, name:"test", extraInfo: {test: "test"}}]
 */
export const sendItemToAnalytics = (event, items, quantity = 1, customData) => {
  if (process.env.ENVIRONMENT === 'prod') {
    const params = createAnalyticsParameters(items, quantity);
    sendAnalyticsEvent(event, params, customData);
  }
};
