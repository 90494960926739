export default { beforeCreate: storeInit };

function storeInit() {
  const options = this.$options;
  // store injection
  if (options.store) {
    this.$store = options.store;
  } else if (options.parent && options.parent.$store) {
    this.$store = options.parent.$store;
  }
}
