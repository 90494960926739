import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    user: {
      type: Object,
    },
    userCreditAmount: {
      type: Number,
    },
  },
})
export default class CreditAdviceComponent extends Vue {
  closeCreditAdvice() {
    this.$emit('closedCreditAdvice');
  }
}
