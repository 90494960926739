import Vue from 'vue';
import Component from 'vue-class-component';
import NavbarUser from 'components/NavbarUser/NavbarUser.vue';

import navLinks from 'constants/accountNavigationLinks';

import chevron from 'common/icons/Chevron.svg';
import shoppingBag from 'assets/icons/shopping-bag.svg';
import gift from 'assets/icons/Gift.svg';
import cash from 'common/icons/Cash.svg';

@Component({
  components: {
    NavbarUser,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
})
export default class NavbarMobileUserComponent extends Vue {
  icons = {
    chevron,
    shoppingBag,
    gift,
    cash,
  };
  showMenu = false;
  isMobile = true;
  links = navLinks.map((link, index) => {
    if (link.name === 'Favoritos') {
      return {
        ...link,
        enabled: this.isMobile,
        class: 'bb-py-2'
      };
    }
    return {
      ...link,
      class: index != 0  ? ' bb-py-2' : 'bb-py-1'
    };
  });
}
