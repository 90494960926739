<script setup>
  import BaseHeaderMenuStructure from 'components/Header/BaseHeaderMenuStructure/BaseHeaderMenuStructure.vue';
  import HeaderIconCard from 'components/Header/HeaderIconCard/HeaderIconCard.vue';

  import useIsMobile from 'common/composables/useIsMobile';

  const props = defineProps({
    content: {
      type: Array,
      default: () => [],
    },
    seeAllRoute: {
      type: Object,
    },
  });

  const emit = defineEmits(['closeOpenedMenu']);

  const { isDesktop } = useIsMobile();

  function goBack() {
    emit('closeOpenedMenu');
  }

  function setFilter() {
    console.log('setFilter');
  }

  const contentTitle = {
    title: 'Top 5 regalos 🔥',
    seeAllRoute: {
      ...props.seeAllRoute,
    },
  };
</script>

<template>
  <div class="main-wrapper">
    <base-header-menu-structure
      :showContent="true"
      :contentTitle="contentTitle"
    >
      <template v-slot:content>
        <div :class="['mobile-header', { '--hide': isDesktop }]">
          <span
            v-html="require('common/icons/Arrow.svg')"
            class="arrow-icon"
            @click="goBack"
          />
          <span class="header-title">Top 5 🔥</span>
        </div>
        <div class="content-menu-wrapper">
          <header-icon-card
            v-for="(card, index) in content"
            :cardContent="{ ...card, index: index + 1 }"
            class="card"
          />
        </div>
      </template>
    </base-header-menu-structure>
  </div>
</template>

<style scoped lang="scss">
  .main-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 0px 0px 8px 8px;
    background-color: white;

    .mobile-header {
      min-height: 56px;
      width: 100vw;
      padding: 24px;
      display: flex;
      align-items: center;

      @media (max-width: $screen-sm-max) {
        padding: 0 16px;
      }

      @media (min-width: $screen-sm-min) {
        padding: 0 24px;
      }

      &.--hide {
        display: none;
      }

      .arrow-icon {
        height: 24px;
        width: 24px;
        margin-right: 16px;
      }

      .header-title {
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
      }
    }

    .content-menu-wrapper {
      display: flex;
      flex-wrap: nowrap;
      height: 100%;
      width: 100%;

      @media (max-width: $screen-sm-max) {
        display: grid;
        grid-template-columns: repeat(2, min-content);
        row-gap: 24px;
        column-gap: 16px;
        justify-content: center;
        padding-top: 24px;
      }

      .card {
        margin-right: 24px;

        &:last-child {
          margin-right: 0;
        }

        @media (max-width: $screen-sm-max) {
          margin: 0;
        }
      }
    }
  }
</style>
