import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import BaseHeader from 'components/Header/BaseHeader';
import BaseFooter from 'components/BaseFooter';

import useAccount from 'composables/useAccount';
import useIsMobile from 'common/composables/useIsMobile.js';

import CartQuery from 'assets/graphql/queries/Cart.graphql';
import CategoriesQuery from 'assets/graphql/queries/Categories.graphql';
import CurrentCampaignQuery from 'assets/graphql/queries/CurrentCampaign.graphql';
import UserWishlistQuery from 'assets/graphql/queries/UserWishlistItems.graphql';

@Component({
  apollo: {
    campaign: {
      query: CurrentCampaignQuery,
      variables: {
        filters: '{"show_banner": true}',
      },
    },
    cart: {
      query: CartQuery,
    },
    categories: {
      query: CategoriesQuery,
    },
    wishlistItems: {
      query: UserWishlistQuery,
      update: data => data.wishitems,
    },
  },
  components: {
    BaseFooter,
    BaseHeader,
  },
  setup() {
    const { getUserData, getUserLogged, onUserStatus, onUserData, loading } =
      useAccount();
    const { isMobile } = useIsMobile();

    return {
      auth_user: getUserData,
      logged_user: getUserLogged,
      onUserStatus,
      onUserData,
      loading,
      isMobile,
    };
  },
})
export default class BaseViewComponent extends Vue {
  categories = [];
  boxes = [];
  baseHeaderProps = {};
  menuOn = false;
  showOverlay = false;
  showHeader = true;
  showFooter = true;
  remindersModalView = false;
  showReminderOnboarding = false;
  savedYWindowScroll = null;

  mounted() {
    this.onUserStatus();
    this.onUserData();
  }

  menuOpen() {
    this.menuOn = !this.menuOn;
  }

  updated() {
    if (this.$route.name !== 'box-catalog' && this.$route.name !== 'box') {
      this.scrollToTop();
    }
    this.$root.$on('updateHeaderAndFooter', ({ showHeader, showFooter }) => {
      this.showHeader = showHeader;
      this.showFooter = showFooter;
    });
  }

  @Watch('$route')
  routeUpdated() {
    this.$refs.header?.routeUpdated();
  }

  @Watch('showOverlay')
  onShowOverlay(val) {
    // Now we manage the ecommerce block scroll from here based in showOverlay
    if (val && this.isMobile) {
      // Save the last window scrollY because if we fix the position and hidde the overflow it loses the scroll position
      this.savedYWindowScroll = window.scrollY;

      // when isMobile and showOverlay === true, we block page scroll
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
    }

    if (!val && this.isMobile) {
      // when isMobile === true and showOverlay === false we enable the scroll again
      document.body.style.overflow = 'auto';
      document.body.style.position = 'static';

      // If routes names matches we return to the last window scrollY saved
      if (
        (this.$route.name === 'box-catalog' || this.$route.name === 'box') &&
        this.savedYWindowScroll
      ) {
        window.scrollTo({
          top: this.savedYWindowScroll,
          behavior: 'instant',
        });
      }
    }
  }

  updateShowOverlay(payload) {
    this.showOverlay = payload;
    }

  showCart() {
    this.$refs.header.showCartMenu();
    this.scrollToTop();
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  refetchUser() {
    this.onUserStatus();
  }

  toggleRemindersModalView(status) {
    this.remindersModalView = status;
  }

  toggleOnboarding(status) {
    this.showReminderOnboarding = status;
  }
}
