import { render, staticRenderFns } from "./BbModal.raw.html?vue&type=template&id=dbe60932&scoped=true&"
import script from "./BbModal.component.js?vue&type=script&lang=vueJs&"
export * from "./BbModal.component.js?vue&type=script&lang=vueJs&"
import style0 from "./BbModal.scoped.scss?vue&type=style&index=0&id=dbe60932&prod&lang=vueScss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbe60932",
  null
  
)

export default component.exports