function getLastTld(hostname) {
  const regex = hostname.match(/\.([a-zA-Z]+)(:\d+)?$/);
  const match = (regex && regex[1]) || 'ar';
  return match.toLowerCase();
}

export function getCountryNameFromTld(hostname) {
  const tld = getLastTld(hostname);
  switch (tld) {
    case ('ar'):
      return 'Argentina';
    case ('cl'):
      return 'Chile';
    case ('es'):
      return 'España';
    case ('mx'):
      return 'México';
    case ('pe'):
      return 'Perú';
    case ('uy'):
      return 'Uruguay';
    default:
      return 'Argentina';
  }
}

export default getLastTld;
