import getLastTld from 'common/services/utils/getLastTld';

export const getHelpUrl = () => {
  const country = getLastTld()
  switch (country) {
    case 'ar':
      return 'https://ayuda.bigbox.com.ar'
    case 'es':
      return 'https://ayuda.bigbox.es'
    case 'uy':
      return 'https://ayuda.bigbox.com.uy'
    case 'pe':
      return 'https://ayuda.bigbox.com.pe'
    case 'mx':
      return 'https://ayuda.bigbox.com.mx'
    case 'cl':
      return 'https://ayuda.bigbox.cl'
  }
}
