import getLastTld from 'common/services/utils/getLastTld';

const tld = getLastTld();

function currency(value, decimalCount = 0, showUSD = false) {
  if (!value && value !== 0) return '';

  value = Number(value);
  value = tld !== 'mx' && tld !== 'pe'
    ? formatMoney(value, decimalCount, ',', '.')
    : formatMoney(value, decimalCount, '.', ',');

  if (showUSD) {
    return `USD ${value}`;
  }
  if (tld === 'es') {
    return `${value} €`;
  }
  if (tld === 'pe') {
    return `S/ ${value}`;
  }
  return `$ ${value}`;
}

function formatMoney(amount, decimalCount, decimal = '.', thousands = ',') {
  decimalCount = Math.abs(decimalCount);
  decimalCount = (Number.isNaN(decimalCount)) ? 0 : decimalCount;
  const negativeSign = amount < 0 ? '-' : '';
  const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount), 10).toString();
  const j = (i.length > 3) ? i.length % 3 : 0;
  const decimals = decimalCount ? `${decimal}${Math.abs(amount - i).toFixed(decimalCount).slice(2)}` : '';

  let price = j ? i.substr(0, j) + thousands : '';
  price = `${price}${i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`)}`;
  return `${negativeSign}${price}${decimals}`;
}

export default currency;
