import Router from 'vue-router';
import queryString from 'query-string';
import findLast from 'lodash/findLast';

import getLastTld from 'common/services/utils/getLastTld';
import { getHelpUrl } from 'common/utils/getHelpUrl';
import LoggedUserQuery from 'assets/graphql/queries/LoggedUser.graphql';
import Microsite from 'assets/graphql/queries/Microsite.graphql';
import HasSellingSpot from 'assets/graphql/queries/HasSellingSpot.graphql';
import BaseView from 'components/BaseView';
import newBaseView from 'views/BaseView/BaseView.vue';

const newBigboxDesign = ['es'].includes(getLastTld());

export default function createRouter(apolloClient) {
  async function resolveMicrosite(to, from, next) {
    const slug = to.path.split('/')[1];
    const { data } = await apolloClient.query({
      query: Microsite,
      variables: {
        slug,
      },
    });
    if (data.microsite) {
      next({
        name: 'box-catalog',
        params: { hash: '~', boxSlug: data.microsite },
      });
    } else {
      next({ name: '404' });
    }
  }

  async function resolveSellingSpots(to, from, next) {
    const { data } = await apolloClient.query({
      query: HasSellingSpot,
    });
    if (data.has_selling_spot) {
      next();
    } else {
      next({ name: '404' });
    }
  }

  const routerOptions = {
    routes: [
      {
        path: '/landing/',
        name: 'landing',
        component: () =>
          import(
            /* webpackChunkName: "landing-page" */ 'components/LandingPage/LandingPage.vue'
          ),
      },
      { path: '/compra/carrito/', name: 'cart', beforeEnter: locationRedirect },
      {
        path: '/personalization/:orderStatus?/:order_signed_id?/',
        name: 'box-personalization',
        props: route => ({
          itemsInfo: route.query.itemsInfo
            ? JSON.parse(route.query.itemsInfo)
            : [],
        }),
        redirect: route => personalizationRedirect(route),
        component: () =>
          import(
            /* webpackChunkName: "box-personalization" */ 'views/Personalization/Personalization.vue'
          ),
        children: [
          {
            path: 'multimedia/',
            name: 'box-personalization-multimedia',
            component: () =>
              import(
                /* webpackChunkName: "box-personalization-multimedia" */ 'components/BoxPersonalization/PersonalizationMultimedia/PersonalizationMultimedia.vue'
              ),
            beforeEnter: (to, from, next) => {
              // Verify if user comes from 'box-personalization-share'
              const isFromShareView = from.name === 'box-personalization-share';

              if (isFromShareView) {
                // Redirect to accountPurchases
                next({ name: 'accountPurchases' });
              } else {
                // Allow access to 'box-personalization-multimedia'
                next();
              }
            },
          },
          {
            path: 'pending/',
            name: 'pending-personalization-status',
            component: () =>
              import(
                /* webpackChunkName: "pending-personalization-status" */ 'components/BoxPersonalization/PendingPersonalizationStatus/PendingPersonalizationStatus.vue'
              ),
          },
          {
            path: 'cancel/',
            name: 'cancel-personalization-status',
            component: () =>
              import(
                /* webpackChunkName: "cancel-personalization-status" */ 'components/BoxPersonalization/CancelPersonalizationStatus/CancelPersonalizationStatus.vue'
              ),
          },
          {
            path: 'share/',
            name: 'box-personalization-share',
            component: () =>
              import(
                /* webpackChunkName: "box-personalization-share" */ 'components/PersonalizationShare/PersonalizationShare.vue'
              ),
          },
        ],
      },
      {
        path: '/unboxing/:codeHashed/',
        name: 'digital-unboxing',
        redirect: { name: 'unboxing-giftcard-view' },
        component: () =>
          import(
            /* webpackChunkName: "digital-unboxing" */ 'views/DigitalUnboxing/DigitalUnboxing.vue'
          ),
        children: [
          {
            path: 'giftcard-view/',
            name: 'unboxing-giftcard-view',
            component: () =>
              import(
                /* webpackChunkName: "digital-unboxing-details" */ 'components/UnboxingCustomizedGiftcardView/UnboxingCustomizedGiftcardView.vue'
              ),
          },
          {
            path: 'multimedia-view/',
            name: 'unboxing-multimedia-view',
            component: () =>
              import(
                /* webpackChunkName: "digital-unboxing-confirmation" */ 'components/UnboxingMultimediaView/UnboxingMultimediaView.vue'
              ),
          },
          {
            path: 'unboxing-login/',
            name: 'unboxing-login',
            component: () =>
              import(
                /* webpackChunkName: "digital-unboxing-login" */ 'components/UnboxingLogin/UnboxingLogin.vue'
              ),
          },
        ],
      },
      {
        path: '/',
        component: newBigboxDesign ? newBaseView : BaseView,
        children: [
          {
            path: '',
            name: 'home',
            component: () =>
              newBigboxDesign
                ? import(
                    /* webpackChunkName: "newHomeView" */ 'views/Home/Home.vue'
                  )
                : import(
                    /* webpackChunkName: "homeView" */ 'components/HomeView'
                  ),
          },
          {
            path: 'regalos/',
            name: 'products',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "filters-view" */ 'views/Filters/Filters.vue'
              ),
          },
          {
            path: 'regalos/:categorySlug/:boxSlug/',
            name: 'box',
            component: () =>
              newBigboxDesign
                ? import(
                    /* webpackChunkName: "newBoxView" */ 'views/Box/Box.vue'
                  )
                : import(
                    /* webpackChunkName: "boxView" */ 'components/BaseBoxView'
                  ),
          },
          {
            path: 'regalos/:categorySlug/:boxSlug/:activitySlug/',
            name: 'boxActivity',
            component: () =>
              newBigboxDesign
                ? import(
                    /* webpackChunkName: "newActivityView" */ 'views/ActivityView/ActivityView.vue'
                  )
                : import(
                    /* webpackChunkName: "activityView" */ 'components/BaseActivityView'
                  ),
          },
          {
            path: 'regalos/regalosconopciones/:customSlug/:categorySlug/:boxSlug/',
            name: 'customBox',
            component: () =>
              import(
                /* webpackChunkName: "customBoxView" */ 'components/CustomBoxes/BaseCustomBoxesView/BaseCustomBoxesView.vue'
              ),
          },
          {
            path: 'experiencias/:activitySlug/',
            name: 'activity',
            component: () =>
              newBigboxDesign
                ? import(
                    /* webpackChunkName: "newActivityView" */ 'views/ActivityView/ActivityView.vue'
                  )
                : import(
                    /* webpackChunkName: "activityView" */ 'components/BaseActivityView'
                  ),
          },
          {
            path: 'experiencias/:activitySlug/:boxSlug/',
            name: 'boxActivityView',
            component: () =>
              newBigboxDesign
                ? import(
                    /* webpackChunkName: "newActivityView" */ 'views/ActivityView/ActivityView.vue'
                  )
                : import(
                    /* webpackChunkName: "activityView" */ 'components/BaseActivityView'
                  ),
            props: route => {
              const boxSlug = route.params.boxSlug;
              return boxSlug ? { boxSlug } : { boxSlug: null };
            },
          },
          {
            path: 'acerca-de-bigbox/',
            name: 'aboutBigbox',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "about-bigbox-view" */ 'components/WhatIsBBView'
              ),
          },
          {
            path: 'como-funciona/',
            name: 'howItWorks',
            component: () =>
              import(
                /* webpackChunkName: "how-it-works-view" */ 'components/HowItWorksView'
              ),
          },
          {
            path: 'trabaja-con-nosotros/',
            name: 'workWithUs',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "workWithUsView" */ 'components/WorkWithUsView'
              ),
          },
          {
            path: 'encuesta/completa/',
            name: 'answeredSurvey',
            component: () =>
              import(
                /* webpackChunkName: "surveys-view" */ 'components/Surveys/AnsweredSurvey'
              ),
          },
          {
            path: 'encuesta/invalida/',
            name: 'invalidSurvey',
            component: () =>
              import(
                /* webpackChunkName: "surveys-view" */ 'components/Surveys/InvalidSurvey'
              ),
          },
          {
            path: 'encuesta/gracias/:hashQualitySurvey/',
            name: 'thanks_survey',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "surveys-view" */ 'components/Surveys/SurveyFeedback'
              ),
          },
          {
            path: 'encuesta/calidad/',
            name: 'qualitySurvey',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "surveys-view" */ 'components/Surveys/QualitySurveyView'
              ),
          },
          {
            path: 'encuesta/travel/',
            name: 'travelQualitySurvey',
            component: () =>
              import(
                /* webpackChunkName: "surveys-view" */ 'components/Surveys/TravelQuality'
              ),
          },
          {
            path: 'encuesta/pickup/',
            name: 'pickUpSurvey',
            component: () =>
              import(
                /* webpackChunkName: "surveys-view" */ 'components/Surveys/PickUpSurveyView'
              ),
          },
          {
            path: 'encuesta/physical/',
            name: 'physicalSurvey',
            component: () =>
              import(
                /* webpackChunkName: "surveys-view" */ 'components/Surveys/PhysicalSurveyView'
              ),
          },
          {
            path: 'encuesta/retail/',
            name: 'retailSurvey',
            component: () =>
              import(
                /* webpackChunkName: "surveys-view" */ 'components/Surveys/RetailSurveyView'
              ),
          },
          {
            path: 'cadenaderegalos/',
            name: 'giftChain',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "gift-chain-view" */ 'components/GiftChain'
              ),
          },
          {
            path: 'activity-sale/',
            name: 'activity-sale',
            component: () =>
              import(
                /* webpackChunkName: "activity-sale" */ 'components/ActivitySaleView'
              ),
            meta: { disableScroll: true },
          },
          {
            path: 'activity-sale/:activitySlug/',
            name: 'activity-sale-activity',
            component: () =>
              import(
                /* webpackChunkName: "activity-view" */ 'components/BaseActivityView'
              ),
          },
          {
            path: 'tata/',
            name: 'activity-sale-tata',
            beforeEnter: locationRedirect,
          },
          {
            path: 'tata/:activitySlug/',
            name: 'tata-activity',
            component: () =>
              import(
                /* webpackChunkName: "activity-view" */ 'components/BaseActivityView'
              ),
          },
          {
            path: 'ancapuntos/',
            name: 'activity-sale-ancapuntos',
            beforeEnter: locationRedirect,
          },
          {
            path: 'ancapuntos/:activitySlug/',
            name: 'ancapuntos-activity',
            component: () =>
              import(
                /* webpackChunkName: "activity-view" */ 'components/BaseActivityView'
              ),
          },
          {
            path: 'experiencias-catalogo/:hash/:activitySlug/',
            name: 'activity-catalog',
            component: () =>
              import(
                /* webpackChunkName: "activity-view" */ 'components/BaseActivityView'
              ),
          },
          {
            path: 'giftcard-viajes/',
            name: 'gift-cards-trip',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "giftcard-travel-view" */ 'components/GiftCardsTripView'
              ),
          },
          {
            path: 'cerrar-sesion/',
            name: 'logout',
            beforeEnter: locationRedirect,
          },
          {
            path: 'turegalo/',
            name: 'activate',
            component: () =>
              import(
                /* webpackChunkName: "activate-view" */ 'components/ActivationView'
              ),
          },
          {
            path: 'ingresar/',
            name: 'login',
            component: () =>
              import(
                /* webpackChunkName: "activate-view" */ 'components/LoginView'
              ),
          },
          {
            path: 'registro/',
            name: 'registro',
            component: () =>
              import(
                /* webpackChunkName: "activate-view" */ 'components/RegisterView'
              ),
          },
          {
            path: 'turegalo/:codeHashed?/',
            name: 'activate-hashcode',
            component: () =>
              import(
                /* webpackChunkName: "activate-view" */ 'components/ActivationView'
              ),
            props: true,
          },
          {
            path: 'wishlist/:wishHash?/',
            name: 'wishlist',
            component: () =>
              import(
                /* webpackChunkName: "wishlist-view" */ 'components/WishlistView'
              ),
            props: true,
            meta: {
              title: 'Wishlist',
            },
          },
          {
            path: 'campa%C3%B1a/:campaignSlug/',
            name: 'new-custom-campaing',
            component: () =>
              import(
                /* webpackChunkName: "campaign-view" */ 'components/Campaign/BaseCustomCampaign'
              ),
          },
          {
            path: 'landings/:landingSlug/',
            name: 'new-custom-landing',
            component: () =>
              import(
                /* webpackChunkName: "custom-landing-view" */ 'components/CustomLanding/BaseCustomLanding/BaseCustomLanding.vue'
              ),
          },
          { path: 'hotsale/', name: 'hotsale', beforeEnter: locationRedirect },
          {
            path: 'cybermonday/',
            name: 'cybermonday',
            beforeEnter: locationRedirect,
          },
          {
            path: 'contacto/',
            name: 'contact',
            beforeEnter: contactRedirect,
            component: () =>
              import(
                /* webpackChunkName: "faq-view" */ 'components/BaseHelpCenterView'
              ),
            props: true,
            children: [
              {
                path: 'faq/',
                name: 'faq',
                component: () => import('components/BaseFAQView'),
              },
            ],
          },
          {
            path: 'pre/catalogo/:hash/',
            name: 'pre-catalog',
            component: () =>
              import(
                /* webpackChunkName: "pre-catalog-view" */ 'components/ActivationPreCatalogView'
              ),
          },
          {
            path: 'catalogo/:hash/',
            name: 'catalog-resolver',
            component: () =>
              import(
                /* webpackChunkName: "catalog-resolver-view" */ 'components/CatalogResolver'
              ),
          },
          {
            path: 'catalogo/:hash/:boxSlug/',
            name: 'box-catalog',
            component: () =>
              import(
                /* webpackChunkName: "catalog-view" */ 'components/BoxCatalogView'
              ),
          },
          {
            path: 'catalogo-test-concierge/:hash/:boxSlug/',
            name: 'concierge-test-catalog',
            component: () =>
              import(
                /* webpackChunkName: "concierge-test-catalog-view" */ 'components/BoxCatalogView'
              ),
          },
          {
            path: 'catalogo/:hash/:boxSlug/:activitySlug/',
            name: 'box-activity-catalog',
            component: () =>
              import(
                /* webpackChunkName: "activity-view" */ 'components/BaseActivityView'
              ),
          },
          {
            path: 'regalendario/:hash/',
            name: 'regalendario-landing',
            component: () =>
              import(
                /*webpackChunkName: "regalendario-landing"*/ 'components/RegalendarioLanding/RegalendarioLanding.vue'
              ),
          },
          {
            path: 'regalendario/regalo-ideal/:reminderSignedId/',
            name: 'reminder-ideal-gift',
            component: () =>
              import(
                /*webpackChunkName: "reminder-ideal-gift"*/ 'components/Reminders/ReminderIdealGifts/ReminderIdealGifts.vue'
              ),
          },
          {
            path: 'puntos-de-venta/',
            name: 'sellingSpots',
            beforeEnter: resolveSellingSpots,
            component: () =>
              import(
                /* webpackChunkName: "selling-spots-view" */ 'components/SellingSpotsByCountry'
              ),
          },
          {
            path: 'empresas/',
            name: 'companies',
            beforeEnter: locationRedirect,
          },
          {
            path: 'politicas-y-garantias/',
            name: 'policiesAndGuarantees',
            component: () =>
              import(
                /* webpackChunkName: "policies-view" */ 'components/PoliciesAndGuarantees'
              ),
          },
          {
            path: 'defensa-al-consumidor/',
            name: 'consumerDefense',
            component: () =>
              import(
                /* webpackChunkName: "consumer-defense-view" */ 'components/ConsumerDefense/ConsumerDefense.vue'
              ),
          },
          {
            path: 'terminos-y-condiciones/',
            name: 'termsAndConditions',
            component: () =>
              import(
                /* webpackChunkName: "tos-view" */ 'components/TermsAndConditions'
              ),
          },
          {
            path: 'terminos-y-condiciones-dia-del-amigo/',
            name: 'termsAndConditionsFriendsDay',
            component: () =>
              import(
                /* webpackChunkName: "tos-view" */ 'components/TermsAndConditionsFriendsDay'
              ),
          },
          {
            path: 'reclamaciones/',
            name: 'claim',
            beforeEnter(to, from, next) {
              if (getLastTld(window.location.hostname) !== 'pe') {
                next('404');
              }
              next();
            },
            component: () =>
              import(
                /* webpackChunkName: "claims-view" */ 'components/ClaimView'
              ),
          },
          {
            path: 'contacto-prestador/',
            name: 'partnerContact',
            component: () =>
              import(
                /* webpackChunkName: "partners-contact-view" */ 'components/PartnerForm'
              ),
          },
          {
            path: 'cuenta/',
            component: () =>
              import(
                /* webpackChunkName: "account-view" */ 'components/account/AccountRoot'
              ),
            meta: { loginRequired: true },
            children: [
              {
                path: '',
                name: 'account',
                component: () =>
                  import(
                    /* webpackChunkName: "account-view" */ 'components/account/AccountProfileComponents/AccountProfile'
                  ),
                meta: { disableScroll: true },
              },
              {
                path: 'regalos/',
                name: 'accountGifts',
                component: () =>
                  import(
                    /* webpackChunkName: "account-view" */ 'components/account/YourGifts/AccountYourGifts'
                  ),
                meta: { disableScroll: true },
              },
              {
                path: 'compras/',
                name: 'accountPurchases',
                component: () =>
                  import(
                    /* webpackChunkName: "account-view" */ 'components/account/AccountYourBuys'
                  ),
                meta: { disableScroll: true },
              },
              // {
              //   path: 'grupos/',
              //   name: 'groups',
              //   component: () =>
              //     import(
              //       /* webpackChunkName: "account-view" */ 'components/account/AccountYourGroups'
              //     ),
              //   meta: { disableScroll: true },
              // },
              {
                path: 'credito/',
                name: 'accountCredit',
                component: () =>
                  import(
                    /* webpackChunkName: "account-view" */ 'components/account/AccountCredit'
                  ),
                meta: { disableScroll: true },
              },
              {
                path: 'regalendario/',
                name: 'regalendario',
                component: () =>
                  import(
                    /* webpackChunkName: "account-view-regalendario" */ 'components/account/AccountRegalendario/AccountRegalendario.vue'
                  ),
                meta: { disableScroll: true },
                props: true,
              },
              {
                path: 'cambio-credito-free/:signedCode?/',
                name: 'changeBoxToCreditToExceedDeadlineExtend',
                component: () =>
                  import(
                    /* webpackChunkName: "account-view" */ 'components/account/ChangeBoxToCreditToExceedDeadlineExtend'
                  ),
                meta: { disableScroll: true },
                props: true,
              },
              {
                path: 'puntos-bigbox/',
                name: 'accountPoints',
                component: () =>
                  import(
                    /* webpackChunkName: "account-view" */ 'components/account/AccountBigBoxPoints'
                  ),
                meta: { disableScroll: true },
              },
              {
                path: '/experiencias-vividas/',
                name: 'livedExperiences',
                component: () =>
                  import(
                    /* webpackChunkName: "account-view" */ 'components/account/YourGifts/AccountYourGifts'
                  ),
                meta: { disableScroll: true },
              },
            ],
          },
          {
            path: 'restablecer-contrasena/ingresar-email/',
            name: 'recover-password-email-form',
            component: () =>
              import(
                /* webpackChunkName: "recoverPasswordForm" */ 'components/PasswordRecoveryEmailFormView'
              ),
          },
          {
            path: 'restablecer-contrasena/email-enviado/',
            name: 'recover-password-message',
            component: () =>
              import(
                /* webpackChunkName: "recoverPasswordForm" */ 'components/PasswordRecoveryMessage'
              ),
          },
          {
            path: 'restablecer-contrasena/restablecer/finalizado/',
            name: 'changed-password-message',
            component: () =>
              import(
                /* webpackChunkName: "recoverPasswordForm" */ 'components/PasswordRecoveryMessage'
              ),
            props: { successMessage: true },
          },
          {
            path: 'restablecer-contrasena/restablecer/:token/',
            name: 'recover-password-change-password',
            component: () =>
              import(
                /* webpackChunkName: "recoverPasswordForm" */ 'components/PasswordRecoveryChangePasswordForm'
              ),
          },
          {
            path: 'giftcard-activada/',
            name: 'giftCardCode',
            component: () =>
              import(
                /* webpackChunkName: "gift-card-view" */ 'components/GiftCardCode'
              ),
            meta: { loginRequired: true },
          },
          {
            path: 'viajes/',
            name: 'travel',
            component: () =>
              import(
                /* webpackChunkName: "travel" */ 'components/Travel/HomePackageView'
              ),
          },
          {
            path: 'viajes/escapada/:slug/',
            name: 'package',
            component: () =>
              import(
                /* webpackChunkName: "package" */ 'components/Travel/PackageView'
              ),
          },
          {
            path: 'mailing/',
            name: 'mailing',
            meta: { loginRequired: true },
            component: () =>
              import(
                /* webpackChunkName: "mailing-view" */ 'components/MailingView'
              ),
          },
          {
            path: 'desuscribir/',
            name: 'unsubscribe',
            component: () =>
              import(
                /* webpackChunkName: "unsubscribe-view" */ 'components/UnsubscribeView'
              ),
          },
          {
            path: 'cargar-subfiltros/',
            name: 'subfilters',
            component: () =>
              import(
                /* webpackChunkName: "subfilters-view" */ 'views/SubfiltersEdition/SubfiltersEdition.vue'
              ),
          },
          {
            path: 'trescruces/',
            redirect: {
              name: 'box-catalog',
              params: { hash: '~', boxSlug: 'trescruces' },
            },
          },
          {
            path: '500/',
            name: '500',
            component: () =>
              import(
                /* webpackChunkName: "500-view" */ 'components/InternalErrorView'
              ),
          },
          {
            path: '404/',
            name: '404',
            component: () =>
              import(
                /* webpackChunkName: "400-view" */ 'components/NotFoundView'
              ),
          },
          {
            path: '*',
            beforeEnter: resolveMicrosite,
          },
        ],
      },
    ],
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) return savedPosition;
      // eslint-disable-next-line consistent-return
      if (to.matched.some(m => m.meta.disableScroll)) return;
      const scrollToMatch = findLast(to.matched, match => {
        const component = match.components.default;
        return component && 'scrollTo' in component.options;
      });
      if (scrollToMatch) {
        const { scrollTo } = scrollToMatch.components.default.options;
        if (typeof scrollTo === 'function') {
          return scrollTo(to, from);
        }
        return scrollTo;
      }
      return { x: 0, y: 0 };
    },
    parseQuery(query) {
      return queryString.parse(query, { arrayFormat: 'bracket' });
    },
    stringifyQuery(query) {
      const strQuery = queryString.stringify(query, { arrayFormat: 'bracket' });
      if (!strQuery) return '';
      return `?${strQuery}`;
    },
  };

  routerOptions.routes.forEach(makeRouteStrict);

  const router = new Router(routerOptions);
  router.beforeEach((to, from, next) =>
    handleAuth(to, from, next, apolloClient),
  );

  return router;
}

function makeRouteStrict(route) {
  if (!route.pathToRegexpOptions) {
    route.pathToRegexpOptions = {};
  }
  route.pathToRegexpOptions.strict = true;
  if (route.children) route.children.forEach(makeRouteStrict);
}

function locationRedirect(to) {
  window.location.href = to.fullPath;
}

function contactRedirect() {
  window.location.href = getHelpUrl();
}

async function handleAuth(to, from, next, apolloClient) {
  if (to.matched.some(m => m.meta.loginRequired)) {
    const { data } = await apolloClient.query({
      query: LoggedUserQuery,
    });
    if (!data.logged_user) {
      next({
        name: 'login',
        query: {
          nextUrl: to.fullPath.includes('/cambio-credito-free/')
            ? to.fullPath
            : null,
        },
      });
    } else {
      next();
    }
  } else {
    next();
  }
}

function personalizationRedirect(route) {
  // When 'box-personalization' father route is initialized we redirect to one of it's children
  // If orderStatus is pending we redirect to pending view
  // If orderStatus is cancel we redirect to cancel view (is not available yet)
  // If orderStatus is success we redirect to multimedia view
  // If orderStatus is undefined we also redirect to multimedia view (this means that is coming from profile and no order status is existent)
  let routeName;
  switch (route.params.orderStatus) {
    case 'pending':
    case 'progress':
      routeName = 'pending-personalization-status';
      break;
    case 'cancel':
    case 'fail':
      routeName = 'cancel-personalization-status';
      break;
    case 'success':
    case null:
    case undefined:
      routeName = 'box-personalization-multimedia';
      break;
  }
  const routePayload = {
    name: routeName,
    params: {
      ...route.params,
    },
    query: { ...route.query },
  };
  return routePayload;
}
