<template>
  <div class="card-wrapper">
    <div v-if="icon" class="icon" v-html="icons[icon]" />
    <div v-else class="number">{{ cardContent.index }}</div>
    <router-link :to="cardContent.product_url" class="card-content">
      <img class="image" :src="cardContent.product_image_url" />
      <span class="name">{{ cardContent.name }}</span>
      <span class="price">{{ cardContent.price | currency }}</span>
    </router-link>
  </div>
</template>

<script>
  import Vue from 'vue';
  import Component from 'vue-class-component';

  import StarIcon from 'assets/icons/star2.svg';

  @Component({
    props: {
      cardContent: {
        type: Object,
      },
      icon: {
        type: String,
        default: '',
      },
    },
  })
  export default class BaseHeaderMenuStructureComponent extends Vue {
    icons = {
      star: StarIcon,
    };
  }
</script>

<style scoped lang="scss">
  .number {
    color: var(--grayscale-g-04-dividers, #e0e0e0);
    text-align: left;
    font-size: 120px;
    font-style: normal;
    font-weight: 700;
    line-height: 0;
    position: absolute;
    width: 50px;
    top: 46px;
    left: 12px;
    @media (max-width: $screen-sm-max) {
      top: 46px;
      left: -8px;
    }
  }

  .card-wrapper {
    width: 212px;
    height: 209px;
    display: flex;
    position: relative;
    justify-content: end;

    @media (max-width: $screen-sm-max) {
      height: auto;
      width: 163px;
    }

    .icon {
      position: absolute;
      top: 8px;
      left: 0;
      width: 80px;
      height: 80px;

      @media (max-width: $screen-sm-max) {
        top: 4px;
        width: 56px;
        height: 56px;
      }
    }

    .card-content {
      display: flex;
      flex-direction: column;
      width: 152px;
      height: 100%;
      border: none;
      z-index: 1;

      @media (max-width: $screen-sm-max) {
        width: 120px;
        height: 100%;
      }

      .image {
        width: 100%;
        height: 152px;
        margin-bottom: 8px;
        border-radius: 8px;

        @media (max-width: $screen-sm-max) {
          width: 100%;
          height: 120px;
        }
      }

      .name {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 8px;
      }

      .price {
        font-size: 15px;
        font-weight: 400;
        line-height: 19px;
      }
    }
  }
</style>
